import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  getAllUsersBySearchString,
  forceDeleteUserMutation,
  updateUsersInviteWithPasswordMutation
} from '../../graphql/user';
import { EditRounded, Delete, Mail, VpnKey } from '@material-ui/icons';
import {
  FormControl,
  Icon,
  LinearProgress,
  MenuItem,
  Select,
  SvgIcon,
} from '@material-ui/core';
import { getProducts } from '../../graphql/product';
import { getAllGroups } from '../../graphql/group';
import { useState } from 'react';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import LicensesForm from './LicensesForm';
import { deleteLicenseMutation } from '../../graphql/license';
import UserForm from './UserForm';
import UserEditForm from './UserEditForm';
import ConfirmationDialog from '../../components/helpers/ConfirmationDialog';
import { getAllInstitutions } from '../../graphql/institution';
import AsyncTable from '../../components/helpers/AsyncTable';
import ResendEmailDialog from '../../components/helpers/ResendEmailDialog';
import ResetPasswordDialog from '../../components/helpers/ResetPasswordDialog';

const StyledFilter = styled(FormControl)`
  width: 200px;
  margin-right: 20px !important;

  #institution-filter {
    padding-left: 30px;
  }

  svg {
    position: absolute;
    bottom: 5px;
  }
`;

const TableStyleWrapper = styled.div`
  tbody td:nth-child(2),
  th:nth-child(2) {
    width: 400px !important;
  }

  tbody td:nth-child(3),
  th:nth-child(3) {
    width: 50px !important;
  }
  tbody td:nth-child(5),
  th:nth-child(5),
  tbody td:nth-child(6),
  th:nth-child(6) {
    width: 200px !important;
  }
`;

const LicenseTable = styled.div`
  .customer-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-top: 5px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .customer-info {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    font-size: 14px;
  }
  .licenses {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% - 210px);
    flex-shrink: 0;
  }
  .license-row {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
  }

  .license-action {
    width: 50px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .thead {
    margin-bottom: 5px;
  }
  .thead,
  .thead .customer-info {
    display: flex;
    font-size: 12px;
  }
`;

export function Users({ history, user, ...otherProps }) {
  const [openLicenceDialog, setOpenLicenceDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [openLicenceDeleteDialog, setOpenLicenceDeleteDialog] = useState(false);
  const [openUserDeleteDialog, setOpenUserDeleteDialog] = useState(false);
  const [openUserEditDialog, setOpenUserEditDialog] = useState(false);
  const [licenceIdToDelete, setLicenceIdToDelete] = useState(null);
  const [licenceIdToInvite, setLicenceIdToInvite] = useState(null);
  const [licenceIdToInviteAndResetPassword, setLicenceIdToInviteAndResetPassword] = useState(null);

  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingSearch, setIsFetchingSearch] = useState(false);

  const [filteredInstitution, setFilteredInstitution] = useState('');

  // resend mail functionality
  const [openResendMailDialog, setOpenResendMailDialog] = useState(false);
  const [resendMailWithPassword, setResendMailWithPassword] = useState(false);

  // resend password functionality
  const [openResendPasswordDialog, setOpenResendPasswordDialog] = useState(false);


  const [deleteLicence] = useMutation(deleteLicenseMutation);
  const [deleteUser] = useMutation(forceDeleteUserMutation);
  const [updateUsersInviteWithPassword] = useMutation(updateUsersInviteWithPasswordMutation);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const searchRef = useRef(searchValue);
  searchRef.current = searchValue;

  let { data: productsData } = useQuery(getProducts);
  let { data: groupsData } = useQuery(getAllGroups);
  let { data: institutionData } = useQuery(getAllInstitutions);

  const handleClose = () => {
    setOpenLicenceDeleteDialog(false);
    setLicenceIdToDelete(null);
    setLicenceIdToInvite(null);
    setLicenceIdToInviteAndResetPassword(null);
    setOpenLicenceDialog(false);
    setOpenUserDeleteDialog(false);
    setOpenUserEditDialog(false);
    setSelectedLicense(null);
    setOpenLicenceDeleteDialog(false);
    setOpenAddUserDialog(false);
    setSelectedUser(null);
    setOpenResendMailDialog(false);
    setOpenResendPasswordDialog(false);
    setResendMailWithPassword(false);
    handleRefetchUsers({ query: searchRef.current, institutionQuery: filteredInstitution, page: page + 1 });
  };

  let { loading: isLoading, data, refetch } = useQuery(
    getAllUsersBySearchString,
    {
      variables: { query: '',institutionQuery: filteredInstitution, page: 1 },
    },
  );

  useEffect(() => {
    if (!isLoading && isFetchingSearch) setIsFetchingSearch(false);
  }, [isLoading]);

  const handleSearch = () => {
    setIsFetchingSearch(true);
    setPage(0);
    handleRefetchUsers({ query: searchRef.current,institutionQuery: filteredInstitution, page: 1 });
  };

  const handlePageChange = (page) => {
    setPage(page);
    handleRefetchUsers({ query: searchRef.current,institutionQuery: filteredInstitution, page: page + 1 });
  };

  function handleAddLicenceButton(rowData) {
    setSelectedUser(rowData);
    setOpenLicenceDialog(true);
  }

  function handleEditUserButton(rowData) {
    setSelectedUser(rowData);
    setOpenUserEditDialog(true);
  }

  function handleDeleteUserButton(rowData) {
    setOpenUserDeleteDialog(true);
    setSelectedUser(rowData);
  }

  function handleEditLicenceButton(rowData, license) {
    setSelectedUser(rowData);
    setSelectedLicense(license);
    setOpenLicenceDialog(true);
  }

  function handleSendEmailButton(rowData) {
    setSelectedUser(rowData);
    setOpenResendMailDialog(true);
  }

  function handleResetPasswordButton(rowData) {
    setSelectedUser(rowData);
    setOpenResendPasswordDialog(true);
  }

  const handleRefetchUsers = ({ query, filteredInstitution, page }) => {
    refetch({ query, filteredInstitution, page });
  };

  function renderSingleLicenses(rowData, customerList) {
    const tableData = [];
    Object.keys(customerList).map((id) => {
      const customer = customerList[+id];
      tableData.push(
        <div className="customer-row">
          <div className="customer-info">
            <p style={{ marginTop: 0 }}>
              {customer.first_name} {customer.last_name}
            </p>
            <p style={{ marginTop: 0 }}>
              <a href={'mailto:' + customer.email}>{customer.email}</a>
            </p>
          </div>
          <div className="licenses">
            {customer.licenses.map((license) => (
              <div className="license-row">
                <div className="license-action">
                  <Icon
                    onClick={() => handleEditLicenceButton(rowData, license)}
                    style={{
                      color: '#FFAC1B',
                      cursor: 'pointer',
                      marginRight: '5px',
                      fontSize: '20px',
                    }}>
                    edit
                  </Icon>
                  <Icon
                    onClick={() => {
                      setOpenLicenceDeleteDialog(true);
                      setLicenceIdToDelete(license.id);
                    }}
                    style={{
                      color: 'rgb(255, 104, 57)',
                      cursor: 'pointer',
                      fontSize: '20px',
                    }}>
                    delete
                  </Icon>
                </div>
                <div className="license-info">{license?.product?.name}</div>
              </div>
            ))}
          </div>
        </div>,
      );
    });

    return tableData;
  }

  function renderProductLicense(rowData) {
    if (rowData.licenses.length < 1) {
      return '';
    } else {
      const licensesByCustomer = {};
      rowData.licenses.forEach((license) => {
        if (!license.customer) {
          return;
        }
        const { email, id, first_name, last_name } = license.customer;
        if (id in licensesByCustomer) {
          licensesByCustomer[id].licenses.push(license);
        } else {
          licensesByCustomer[id] = {
            email,
            first_name,
            last_name,
            licenses: [license],
          };
        }
      });
      return (
        <LicenseTable>
          <div className="thead">
            <div className="customer-info">Käufer</div>
            <div>Lizenz</div>
          </div>
          <div className="tbody">
            {renderSingleLicenses(rowData, licensesByCustomer)}
          </div>
        </LicenseTable>
      );
    }
  }

  const renderFilters = () => {
    return (
      <>
        {!!institutionData && (
          <StyledFilter>
            <SvgIcon color="#212121" style={{ fontSize: 20 }}>
              <path d="M4.25 5.61L10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6l5.74-7.39A1 1 0 0 0 18.95 4H5.04a1 1 0 0 0-.79 1.61z" />
            </SvgIcon>
            <Select
              displayEmpty
              placeholder="Alle Institutionen"
              id="institution-filter"
              value={filteredInstitution}
              onChange={(e) => {
                  setFilteredInstitution(e.target.value);
                  handleSearch()
                }
              }>
              <MenuItem value="">
                <span
                  style={{
                    color: '#A2A2A2',
                    fontSize: 16,
                    fontFamily: 'Roboto',
                  }}>
                  Alle Institutionen
                </span>
              </MenuItem>
              {institutionData.institutions.map((institution) => (
                <MenuItem key={institution.id} value={institution.id}>
                  {institution.institution_name}
                </MenuItem>
              ))}
            </Select>
          </StyledFilter>
        )}
      </>
    );
  };
  const renderActions = (rowData) => {
    return (
      <>
        <EditRounded
          onClick={() => handleEditUserButton(rowData)}
          style={{
            color: '#FFAC1B',
            fontSize: '20px',
            cursor: 'pointer',
            marginRight: '7px',
          }}
        />
        <Delete
          onClick={() => handleDeleteUserButton(rowData)}
          style={{
            color: 'rgb(255, 104, 57)',
            fontSize: '20px',
            cursor: 'pointer',
            marginRight: '8px',
          }}
        />
        {!rowData.email_sent ? ( // REPLACE WITH EMAIL SENT PROP
          <Mail
            onClick={() => {
              handleSendEmailButton(rowData);
              setLicenceIdToInvite(rowData.id);
            }}
            style={{
              color: '#369BEF',
              fontSize: '20px',
              cursor: 'pointer',
              marginRight: '8px',
            }}
          />
        ) : (
          <SvgIcon
            onClick={() => {
              handleSendEmailButton(rowData);
              setLicenceIdToInvite(rowData.id);
            }}
            style={{
              color: '#5AC221',
              fontSize: '20px',
              cursor: 'pointer',
              marginRight: '8px',
            }}>
            <path d="M12 19c0-3.87 3.13-7 7-7 1.08 0 2.09.25 3 .68V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8.08c-.05-.33-.08-.66-.08-1zM4 6l8 5 8-5v2l-8 5-8-5V6zm13.34 16l-3.54-3.54 1.41-1.41 2.12 2.12 4.24-4.24L23 16.34 17.34 22z" />
          </SvgIcon>
        )}
        <VpnKey
          onClick={() => {
            handleResetPasswordButton(rowData)
            setLicenceIdToInviteAndResetPassword(rowData.id);
          }}
          style={{
            color: '#369BEF',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        />
      </>
    );
  };

  const columns = [
    {
      title: '',
      align: 'left',
      sorting: false,
      cellStyle: { minWidth: '128px !important' },
      render: (rowData) => renderActions(rowData),
    },
    {
      title: 'Benutzer',
      align: 'left',
      field: 'first_name',
      cellStyle: { width: 'auto !important' },
      render: (rowData) => {
        return (
          <>
            <p style={{ marginTop: 0 }}>
              {rowData.first_name + ' ' + rowData.last_name}
            </p>
            <p style={{ marginTop: 5 }}>
              <a href={'mailto:' + rowData.email}>{rowData.email}</a>
            </p>
          </>
        );
      },
    },
    {
      title: '',
      sorting: false,
      align: 'center',
      cellStyle: { width: '50px !important', maxWidth: '50px' },
      render: (rowData) => (
        <AddCircleRoundedIcon
          onClick={() => handleAddLicenceButton(rowData)}
          style={{
            color: 'rgb(87,192,49)',
            fontSize: '20px',
            cursor: 'pointer',
          }}
          title="Lizenz hinzufügen"
        />
      ),
    },
    {
      title: 'Produkte',
      sorting: false,
      cellStyle: { width: 'auto !important' },
      render: (rowData) => renderProductLicense(rowData),
    },
    {
      title: 'Gruppe',
      cellStyle: { width: 'auto !important' },
      align: 'right',
      field: 'group.name',
    },
    {
      title: 'Institution',
      align: 'right',
      cellStyle: { width: 'auto !important' },
      field: 'institution.institution_name',
    },
  ];

  return (
    <div className={classNames({ 'is-loading': isLoading })} {...otherProps}>
      {!data ? (
        <LinearProgress />
      ) : (
        <TableStyleWrapper>
          <AsyncTable
            handleAdd={() => setOpenAddUserDialog(true)}
            title="Benutzer"
            columns={columns}
            data={data && data.userSearch && data.userSearch.data}
            total={data && data.userSearch && data.userSearch.totalCount}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            page={page}
            isFetching={isFetchingSearch}
            handlePageChange={handlePageChange}
            pageSize={10}
            customActions
            actionWidth={135}
            renderFilters={renderFilters}
          />
          {openLicenceDialog && (
            <LicensesForm
              selectedUser={selectedUser}
              selectedLicense={selectedLicense}
              handleClose={handleClose}
              userData={data.userSearch.data || []}
              productData={productsData || []}
            />
          )}
          {openUserEditDialog && (
            <UserEditForm
              institutions={institutionData && institutionData.institutions}
              selectedUser={selectedUser}
              handleClose={handleClose}
              groupsData={groupsData.groups || []}
            />
          )}
          {openAddUserDialog && (
            <UserForm
              institutions={institutionData && institutionData.institutions}
              handleClose={handleClose}
              userData={data.userSearch.data || []}
              productData={productsData || []}
              groupsData={groupsData.groups || []}
            />
          )}
          {openLicenceDeleteDialog && (
            <ConfirmationDialog
              title="Soll die Lizenz unwiderruflich gelöscht werden?"
              discardText="Abbrechen"
              discardColor="default"
              isFetching={isFetching}
              discardAction={handleClose}
              confirmText="Ja, löschen"
              confirmColor="secondary"
              confirmAction={async () => {
                setIsFetching(true);
                const id = licenceIdToDelete;
                await deleteLicence({
                  variables: {
                    id,
                  },
                });
                setIsFetching(false);
                handleClose();
              }}
            />
          )}
          {openResendMailDialog && (
            <ResendEmailDialog
              isFetching={isFetching}
              discardAction={handleClose}
              selectedUser={selectedUser}
              value={resendMailWithPassword}
              setValue={(e) => {
                setResendMailWithPassword(e.target.value);
              }}
              confirmAction={async () => {
                setIsFetching(true);
                const id = licenceIdToInvite;
                const generatePassword = false
                await updateUsersInviteWithPassword({
                  variables: {
                    id,
                    generatePassword
                  },
                });
                setIsFetching(false);
                handleSearch()
                setOpenResendMailDialog(false);
              }}
            />
          )}
          {openResendPasswordDialog && (
            <ResetPasswordDialog
              isFetching={isFetching}
              discardAction={handleClose}
              selectedUser={selectedUser}
              value={resendMailWithPassword}
              setValue={(e) => {
                setResendMailWithPassword(e.target.value);
              }}
              confirmAction={async () => {
                setIsFetching(true);
                const id = licenceIdToInviteAndResetPassword;
                const generatePassword = true
                await updateUsersInviteWithPassword({
                  variables: {
                    id,
                    generatePassword
                  },
                });;
                setIsFetching(false);
                setOpenResendPasswordDialog(false)
              }}
            />
          )}
          {openUserDeleteDialog && (
            <ConfirmationDialog
              title={`Soll der Nutzer ${selectedUser.email} unwiderruflich gelöscht werden?`}
              discardText="Abbrechen"
              discardColor="default"
              isFetching={isFetching}
              discardAction={handleClose}
              confirmText="Ja, löschen"
              confirmColor="secondary"
              confirmAction={async () => {
                setIsFetching(true);
                const id = selectedUser.id;
                await deleteUser({
                  variables: {
                    id,
                  },
                });
                setIsFetching(false);
                handleClose();
              }}
            />
          )}
        </TableStyleWrapper>
      )}
    </div>
  );
}

function withRedux({ user }) {
  return { user };
}

export default connect(withRedux, null)(withRouter(Users));
