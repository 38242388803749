import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';

/*MATERIAL UI */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const Form = styled(FormikForm)`
  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
  }

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -22px;
  }
`;

function ProductForm({ handleClose, selectedProduct, handleSubmit }) {
  const validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Feld wird benötigt.';
    }
    return errors;
  };

  const initialProduct = {
    name: !!selectedProduct ? selectedProduct.name : '',
    ios_store_url:
      !!selectedProduct && !!selectedProduct.ios_store_url
        ? selectedProduct.ios_store_url
        : '',
    web_store_url:
      !!selectedProduct && !!selectedProduct.web_store_url
        ? selectedProduct.web_store_url
        : '',

    play_store_url:
      !!selectedProduct && !!selectedProduct.play_store_url
        ? selectedProduct.play_store_url
        : '',

    icon:
      !!selectedProduct && !!selectedProduct.icon ? selectedProduct.icon : '',
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ paddingTop: '15px' }}>
        Produkt {!!selectedProduct ? 'bearbeiten' : 'hinzufügen'}
      </DialogTitle>
      <Formik
        initialValues={initialProduct}
        validate={validateForm}
        validateOnBlur={true}
        onSubmit={handleSubmit}
        render={({ values, errors, handleBlur, touched, handleChange }) => {
          const {
            name,
            ios_store_url,
            web_store_url,
            play_store_url,
            icon,
          } = values;

          return (
            <Form>
              <DialogContent>
                <TextField
                  error={!!touched.name && !!errors.name}
                  helperText={!!touched.name && !!errors.name && errors.name}
                  name="name"
                  label="Name *"
                  value={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  error={!!touched.ios_store_url && !!errors.ios_store_url}
                  helperText={
                    !!touched.ios_store_url &&
                    !!errors.ios_store_url &&
                    errors.ios_store_url
                  }
                  name="ios_store_url"
                  label="IOS Store URL"
                  value={ios_store_url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  error={!!touched.play_store_url && !!errors.play_store_url}
                  helperText={
                    !!touched.play_store_url &&
                    !!errors.play_store_url &&
                    errors.play_store_url
                  }
                  name="play_store_url"
                  label="Play Store URL"
                  value={play_store_url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  error={!!touched.web_store_url && !!errors.web_store_url}
                  helperText={
                    !!touched.web_store_url &&
                    !!errors.web_store_url &&
                    errors.web_store_url
                  }
                  name="web_store_url"
                  label="Web Store URL"
                  value={web_store_url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <TextField
                  error={!!touched.icon && !!errors.icon}
                  helperText={!!touched.icon && !!errors.icon && errors.icon}
                  name="icon"
                  label="Icon URL"
                  value={icon}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </DialogContent>
              <DialogActions style={{ padding: '25px 20px' }}>
                <Button color="default" onClick={handleClose}>
                  Abbrechen
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disableElevation>
                  Speichern
                </Button>
              </DialogActions>
            </Form>
          );
        }}></Formik>
    </Dialog>
  );
}
ProductForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

export default ProductForm;
