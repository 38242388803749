import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { CircularProgress } from '@material-ui/core';

const ConfirmationDialog = ({
  title,
  discardText,
  confirmText,
  confirmColor,
  discardColor,
  discardAction,
  confirmAction,
  isFetching,
}) => {
  return (
    <Dialog
      onClose={discardAction}
      className="licence-form"
      open
      aria-labelledby="form-dialog-title">
      <DialogContent>
        <p>{title}</p>
      </DialogContent>
      <DialogActions>
        {isFetching ? (
          <CircularProgress style={{ width: '20px', height: '20px' }} />
        ) : (
          <>
            <Button onClick={discardAction} color={discardColor}>
              {discardText}
            </Button>
            <Button
              onClick={confirmAction}
              color={confirmColor}
              variant="contained"
              disableElevation>
              {confirmText}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  discardText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmColor: PropTypes.string.isRequired,
  discardColor: PropTypes.string.isRequired,
  discardAction: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default ConfirmationDialog;
