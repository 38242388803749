import React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { routes } from './data';
import { signOut } from '../../redux/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function Menu({ signOut, children, history, useStyles, ...otherProps }) {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left">
      <div style={{ marginTop: '50px', textAlign: 'center' }}>
        <img src="appucations_logo.png" width="220px" />
      </div>
      <div className={classes.toolbar} />
      <List>
        {routes
          .filter((route) => !!route['icon'])
          .map(
            ({ title, link, component, icon: Icon, ...otherProps }, index) => (
              <ListItem
                component={(props) => <Link to={link} {...props} />}
                button
                className={
                  window.location.href.indexOf('products') > -1
                    ? 'product'
                    : window.location.href.indexOf('roles') > -1
                    ? 'roles'
                    : window.location.href.indexOf('groups') > -1
                    ? 'groups'
                    : window.location.href.indexOf('institutions') > -1
                    ? 'institutions'
                    : ''
                }
                selected={history.location.pathname === link}
                key={title}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItem>
            ),
          )}
      </List>
      <div
        onClick={() => signOut()}
        style={{
          width: '150px',
          alignSelf: 'center',
          marginTop: '25px',
          color: '#ff6839',
          cursor: 'pointer',
          textAlign: 'center',
        }}>
        Logout
      </div>
    </Drawer>
  );
}
function withRedux({ signOut }) {
  return { signOut };
}
export default connect(withRedux, { signOut })(withRouter(Menu));
