import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddButton } from '../../../styled/list';
import Plus from '../../../components/icons/plus';
import classNames from 'classnames';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  getProducts,
  createProductMutation,
  deleteProductMutation,
  updateProductMutation,
} from '../../../graphql/product';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import { LinearProgress } from '@material-ui/core';
import { colors as color } from '../../../variables/css';
import DeleteIcon from '@material-ui/icons/Delete';
import AppsIcon from '@material-ui/icons/Apps';
import EditRounded from '@material-ui/icons/EditRounded';
import ProductForm from '../components/ProductForm';
import ConfirmationDialog from '../../../components/helpers/ConfirmationDialog';

const StyledTable = styled.div`
  .MuiTableCell-root p {
    margin-bottom: 0;
  }
  .MuiTableCell-root {
    border: none;
  }
  .MuiTableRow-root:nth-of-type(odd) {
    background-color: #fcfcfc;
  }

  td:first-child,
  th:first-child {
    width: 100px !important;
  }
`;

export function ProductList({ user, history, ...otherProps }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProductDialog, setShowProductDialog] = useState(false);
  const [showDeleteProductDialog, setShowDeleteProductDialog] = useState(false);

  const [updateProduct] = useMutation(updateProductMutation);
  const [createProduct] = useMutation(createProductMutation);
  const [deleteProduct] = useMutation(deleteProductMutation);

  let { loading: isLoading, data, refetch } = useQuery(getProducts);

  const handleClose = () => {
    setSelectedProduct(null);
    setShowDeleteProductDialog(false);
    setShowProductDialog(false);
  };

  useEffect(() => {
    refetch();
  }, []);

  const handleSubmitProduct = (values) => {
    const id = !!selectedProduct ? selectedProduct.id : null;
    handleClose();
    const { name, ios_store_url, web_store_url, play_store_url, icon } = values;
    const variables = {
      name,
      ios_store_url,
      web_store_url,
      play_store_url,
      icon,
      id,
    };
    if (!!id) {
      updateProduct({ variables })
        .then(() => {
          refetch();
        })
        .catch((e) => console.log(e));
    } else {
      createProduct({ variables })
        .then(() => {
          refetch();
        })
        .catch((e) => console.log(e));
    }
  };

  const handleDeleteProduct = () => {
    const { id } = selectedProduct;
    handleClose();
    deleteProduct({ variables: { id } })
      .then(() => {
        refetch();
      })
      .catch((e) => console.log(e));
  };

  function renderActions(rowData) {
    return (
      <>
        <EditRounded
          onClick={() => {
            setSelectedProduct(rowData);
            setShowProductDialog(true);
          }}
          style={{
            color: '#FFAC1B',
            fontSize: '20px',
            cursor: 'pointer',
            marginRight: '7px',
          }}
          title="Produkt bearbeiten"
        />
        <DeleteIcon
          onClick={() => {
            setShowDeleteProductDialog(true);
            setSelectedProduct(rowData);
          }}
          style={{
            color: 'rgb(255, 104, 57)',
            fontSize: '20px',
            cursor: 'pointer',
          }}
          title="Produkt löschen"
        />
      </>
    );
  }

  const columns = [
    {
      title: 'Aktionen',
      align: 'left',
      sorting: false,
      render: (rowData) => renderActions(rowData),
    },
    { title: 'Name', field: 'name' },
    {
      title: 'Apple',
      field: 'ios_store_url',
      render: (data) =>
        data['ios_store_url'] ? (
          <a
            style={{ textDecoration: 'none', color: color.blue }}
            href={data.ios_store_url}
            target="_blank">
            AppStore
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'Google',
      field: 'play_store_url',
      render: (data) =>
        data['play_store_url'] ? (
          <a
            style={{ textDecoration: 'none', color: color.blue }}
            href={data.play_store_url}
            target="_blank">
            PlayStore
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'WebShop',
      field: 'web_store_url',
      render: (data) =>
        data['web_store_url'] ? (
          <a
            style={{ textDecoration: 'none', color: color.blue }}
            href={data.web_store_url}
            target="_blank">
            Web Shop
          </a>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <div className={classNames({ 'is-loading': isLoading })} {...otherProps}>
      {isLoading && <LinearProgress />}
      <AddButton onClick={() => setShowProductDialog(true)}>
        <AppsIcon /> Neues Produkt hinzufügen
      </AddButton>
      <MaterialTable
        localization={{
          toolbar: {
            searchPlaceholder: 'Suche',
          },
        }}
        title="Produkte"
        columns={columns}
        data={data && data['products']}
        options={{
          pageSize:
            data && data['products'].length < 100
              ? data['products'].length
              : 100,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        components={{
          Container: (props) => {
            return <StyledTable {...props} />;
          },
        }}
      />

      {showProductDialog && (
        <ProductForm
          selectedProduct={selectedProduct}
          handleClose={handleClose}
          handleSubmit={handleSubmitProduct}
        />
      )}

      {showDeleteProductDialog && (
        <ConfirmationDialog
          title="Soll das Produkt unwiderruflich gelöscht werden?"
          discardText="Abbrechen"
          discardColor="default"
          discardAction={handleClose}
          confirmText="Ja, löschen"
          confirmColor="secondary"
          confirmAction={handleDeleteProduct}
        />
      )}
    </div>
  );
}

function withRedux({ user }) {
  return { user };
}

export default connect(withRedux, null)(withRouter(ProductList));
