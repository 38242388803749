import { SET_APP_ELEMENT } from '../types';

const initialState = '#root';

export default function appElement(state = initialState, action) {
  switch (action.type) {
    case SET_APP_ELEMENT:
      return action.selector;
    default:
      return state;
  }
}
