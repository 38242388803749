import { api } from './utils';

export function register(data) {
  return api.post('/register', data).then((res) => res.data.success.token);
}

export function login(prepData) {
  const data = {...prepData,
    requester: 'management'
  }
  return api
    .post('/management/login', { ...data, applicationName: process.env.REACT_APP_NAME })
    .then((res) => res.data.success.token);
}

export function logout(token) {
  return api
    .post('/logout', { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data);
}

export function getUserDetails(token) {
  return api
    .get('/details', { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data.success);
}
