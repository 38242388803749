import React, { useEffect } from 'react';
import { List, AddButton } from '../../../styled/list';
import Plus from '../../../components/icons/plus';
import classNames from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { getRoles } from '../../../graphql/role';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import { LinearProgress, Typography } from '@material-ui/core';
import RoleEdit from '../show';

export function RoleList({ user, history, ...otherProps }) {
  let { loading: isLoading, data, refetch } = useQuery(getRoles);

  useEffect(() => {
    refetch();
  });

  function goToAddRole() {
    history.push('/role-add');
  }

  const columns = [
    { title: 'Name', field: 'name' },
    {
      title: 'Berechtigungen (Anzahl)',
      field: 'permissions',
      render: (rowData) => (
        <Typography>{rowData.permissions.length}</Typography>
      ),
    },
  ];
  return (
    <List className={classNames({ 'is-loading': isLoading })} {...otherProps}>
      {isLoading && <LinearProgress />}
      <MaterialTable
        title="Rollen"
        columns={columns}
        data={data && data['roles']}
        options={{
          selection: true,
        }}
        detailPanel={(rowData) => {
          return <RoleEdit history={history} role={rowData} />;
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
      <AddButton style={{ background: '#ffac1b' }} onClick={goToAddRole}>
        <Plus />
      </AddButton>
    </List>
  );
}

function withRedux({ user }) {
  return { user };
}

export default connect(withRedux, null)(withRouter(RoleList));
