import gql from 'graphql-tag';

export const getPermissionsQuery = gql`
  query getPermissions {
    permissions {
      id
      name
    }
  }
`;
