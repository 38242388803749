import React, { useState, useRef, useMemo } from 'react';
import styled from 'styled-components';
import MaterialTable from 'material-table';
import {
  CircularProgress,
  InputAdornment,
  TablePagination,
  TextField,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
  AccountCircle,
  EditRounded,
  Delete,
  GroupWork,
  AccountBalance,
  Apps,
} from '@material-ui/icons';
import { colors } from '../../variables/css';

const StyledTable = styled.div`
  .MuiTableCell-root p {
    margin-bottom: 0;
  }

  tr {
    vertical-align: top;
  }

  th {
    font-family: 'Montserrat', sans-serif;
  }

  td,
  th {
    width: auto !important;
  }

  tbody td:first-child,
  th:first-child {
    width: ${(props) =>
      props.actionWidth ? props.actionWidth : '80'}px !important;
  }

  .MuiTableCell-root {
    border: none;
  }
  .MuiTableRow-root:nth-of-type(odd) {
    background-color: #fcfcfc;
  }
  tfoot.MuiTableFooter-root td {
    display: block !important;
  }
`;

const Toolbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => {
    return props.bg;
  }} !important;

  margin-right: 25px !important;
  padding: 10px 20px !important;

  .MuiSvgIcon-colorPrimary,
  .MuiButton-label {
    color: #fff !important;
  }
`;

const AsyncTable = (props) => {
  const {
    data,
    title,
    columns,
    total,
    handleAdd,
    page,
    searchValue,
    handleSearch,
    setSearchValue,
    handlePageChange,
    isFetching,
    handleEditItem,
    handleDeleteItem,
    pageSize,
    customActions,
    actionWidth,
    renderFilters,
  } = props;
  const [isSearching, setIsSearching] = useState(false);

  const searchTimeout = useRef(null);

  const handleSearchChange = (e) => {
    setIsSearching(true);
    clearTimeout(searchTimeout.current);
    setSearchValue(e.target.value);
    searchTimeout.current = setTimeout(() => {
      setIsSearching(false);
      handleSearch();
    }, 1500);
  };
  function renderActions(rowData) {
    return (
      <>
        <EditRounded
          onClick={() => handleEditItem(rowData)}
          style={{
            color: '#FFAC1B',
            fontSize: '20px',
            cursor: 'pointer',
            marginRight: '7px',
          }}
        />
        <Delete
          onClick={() => handleDeleteItem(rowData)}
          style={{
            color: 'rgb(255, 104, 57)',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        />
      </>
    );
  }

  const renderTable = useMemo(() => {
    return (
      <MaterialTable
        title={title}
        columns={
          customActions
            ? [...columns]
            : [
                {
                  title: '',
                  align: 'left',
                  sorting: false,
                  cellStyle: { width: '80px !important' },

                  render: (rowData) => renderActions(rowData),
                },
                ...columns,
              ]
        }
        data={data}
        options={{
          selection: false,
          pageSize,
          draggable: false,
          emptyRowsWhenPaging: false,
          tableLayout: 'fixed',
          search: false,
        }}
        localization={{
          body: { emptyDataSourceMessage: 'Keine Ergebnisse gefunden' },
        }}
        components={{
          Pagination: (props) => {
            return (
              <TablePagination
                {...props}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[10]}
                count={total}
                page={page}
                onChangePage={(e, page) => handlePageChange(page)}
              />
            );
          },
          Container: (props) => {
            return <StyledTable actionWidth={actionWidth} {...props} />;
          },
        }}
      />
    );
  }, [data]);

  const renderButton = () => {
    switch (title) {
      case 'Gruppen':
        return (
          <StyledButton
            bg={colors.purple}
            onClick={handleAdd}
            startIcon={<GroupWork color="primary" />}>
            Gruppe hinzufügen
          </StyledButton>
        );
      case 'Institutionen':
        return (
          <StyledButton
            bg={colors.dark_blue}
            onClick={handleAdd}
            startIcon={<AccountBalance color="primary" />}>
            Institution hinzufügen
          </StyledButton>
        );
      case 'Produkte':
        return (
          <StyledButton
            bg={colors.blue}
            onClick={handleAdd}
            startIcon={<Apps color="primary" />}>
            Produkt hinzufügen
          </StyledButton>
        );
      default:
        return (
          <StyledButton
            bg={colors.green}
            onClick={handleAdd}
            startIcon={<AccountCircle color="primary" />}>
            Benutzer hinzufügen
          </StyledButton>
        );
    }
  };

  return (
    <div>
      <Toolbar>
        {renderButton()}
        {renderFilters && renderFilters()}
        <TextField
          placeholder="Suche"
          value={searchValue}
          type="search"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {isSearching || isFetching ? (
                  <CircularProgress style={{ width: '24px', height: '24px' }} />
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {renderTable}
    </div>
  );
};

export default AsyncTable;
