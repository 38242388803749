import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import DialogActions from '@material-ui/core/DialogActions';
import { CircularProgress } from '@material-ui/core';

const CheckboxLabel = styled.p`
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
`;

const ResetPasswordDialog = ({
  value,
  setValue,
  selectedUser,
  discardAction,
  confirmAction,
  isFetching,
}) => {
  return (
    <Dialog
      onClose={discardAction}
      className="licence-form"
      open
      aria-labelledby="form-dialog-title">
      <DialogContent>
        <p style={{ lineHeight: 1.5 }}>
          Möchten Sie für  {selectedUser.email} ein neues Passwort generieren?
        </p>
        <CheckboxLabel>
          <Checkbox
            color="default"
            value={value}
            onChange={setValue}
            name="pwfield"
          />
          Neues Passwort generieren
        </CheckboxLabel>
      </DialogContent>
      <DialogActions>
        {isFetching ? (
          <CircularProgress style={{ width: '20px', height: '20px' }} />
        ) : (
          <>
            <Button onClick={discardAction} color="default">
              Abbrechen
            </Button>
            <Button
              onClick={confirmAction}
              color="primary"
              variant="contained"
              disabled={!value}
              disableElevation>
              Senden
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

ResetPasswordDialog.propTypes = {
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  selectedUser: PropTypes.object.isRequired,
  discardAction: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default ResetPasswordDialog;
