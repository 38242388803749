import React from 'react';
import styled, { keyframes } from 'styled-components';
import { transparentize } from 'polished';
import { sizes } from '../../variables/css';

const rotating = keyframes`
  
  25%, 100% {
      transform: rotate(-90deg);
  }
  75% {
      transform: rotate(270deg);
  }
`;

const building = keyframes`
  
  0%, 100% {
      stroke-dasharray: 0, 283;
  }
  25% {
      stroke-dasharray: 142, 283;
  }
  50% {
      stroke-dasharray: 142, 283;
  }
  75% {
      stroke-dasharray: 0, 283;
  }
`;

const Circle = styled.circle`
  fill: none;
  stroke-width: 10;
  transform: rotate(-90deg);
  transform-origin: center;

  animation: ${rotating} 3s linear infinite forwards,
    ${building} 3s linear infinite forwards;
`;

// todo: add pie chart with 66% and rotate it indefinite
export default ({ size, fill: color, rotation, ...otherProps }) => (
  <svg width={sizes.icons[size]} viewBox="0 0 100 100" {...otherProps}>
    <mask id="loading-mask">
      <circle cx="50" cy="50" r="50" fill="white" />
      <circle cx="50" cy="50" r="40" fill="black" />
    </mask>
    <g mask="url(#loading-mask)">
      <circle
        cx="50"
        cy="50"
        r="50"
        fill={transparentize(0.5, color)}
        mask="url(#loading-ring-mask)"
      />
      <Circle cx="50" cy="50" r="45" strokeDasharray="113 283" stroke={color} />
    </g>
  </svg>
);
