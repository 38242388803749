// react
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// styles
import styled from 'styled-components';
import { colors } from '../../variables/css';
// hoc
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
// components
import Loading from '../loading';
import Input from '../../components/dataEntry/input/default';
import { Primary as Button, Default } from '../../components/buttons';
import Error from '../../containers/error';
// data
import { signIn } from '../../redux/actions';
import { useRegister } from './useRegister';
// helper
import * as Yup from 'yup';
import axios from 'axios';
import { useHistory } from 'react-router';

const apiUrl = process.env.REACT_APP_SERVER + '/api/forgot';

const Wrapper = styled(Form)`
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > * {
    margin: 20px 0;
  }
`;

const Heading = styled.h1`
  color: ${colors.dark_blue};
  font-size: 30px;

  text-align: center;
`;

const initialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Deine E-Mail ist ungültig')
    .required('E-Mail ist ein Pflichtfeld'),
});

/**  */
export function ResetPassword({ signIn, history, ...otherProps }) {
  const [emailError, setError] = useState(false);

  const [values, setValues] = useState(null);
  const [loading, error] = useRegister(values);

  function submit(values, { setSubmitting }) {
    // setValues(values);

    if (!validateEmail(values.email)) {
      return;
    }

    const config = {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    };

    axios
      .post(apiUrl, values, config)
      .then(() => {
        setError(true);
      })
      .catch(() => {
        setError(true);
      });
  }

  function reset() {
    setValues(null);
    setError(false);
  }

  function goBack() {
    history.goBack();
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  if (loading) return <Loading />;

  if (error)
    return (
      <Error
        title="Registrierung Fehlgeschlagen"
        description="Bei der Registrierung ist etwas schief gelaufen. Bitte prüfe deine Angaben erneut und versuche es noch einmal."
        onAccept={reset}
      />
    );

  return (
    <>
      {emailError && (
        <Error
          title=""
          description="Zum Ändern Ihres Passworts haben wir eine Nachricht an die von dir angegebene E-Mail-Adresse versendet. Prüfe auch dein Spam-Ordner!"
          onAccept={reset}
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={submit}
        render={({ values, errors, handleChange, handleSubmit }) => {
          return (
            <Wrapper {...otherProps}>
              <Heading>E-Mail zum Zurücksetzen deines Passwortes:</Heading>
              <Input
                name="email"
                type="text"
                placeholder="E-Mail"
                value={values['email']}
                style={{ width: '40%' }}
                onChange={handleChange}
              />
              <Button type="submit" onClick={handleSubmit}>
                Versende E-Mail
              </Button>
              <Default onClick={goBack}>Zurück</Default>
            </Wrapper>
          );
        }}
      />
    </>
  );
}

ResetPassword.propTypes = {
  signIn: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

ResetPassword.defaultProps = {};

export default connect(null, { signIn })(withRouter(ResetPassword));
