import gql from 'graphql-tag';

export const createLicenseMutation = gql`
  mutation createLicense(
    $time_to_live: String!
    $product_name: String!
    $user_email: String!
    $customer_email: String!
    $date_of_activation: String
    $device_limit: Int
  ) {
    createLicense(
      time_to_live: $time_to_live
      product_name: $product_name
      user_email: $user_email
      customer_email: $customer_email
      date_of_activation: $date_of_activation
      device_limit: $device_limit
    ) {
      id
      time_to_live
      product {
        id
      }
      user {
        id
      }
      customer {
        id
      }
      date_of_activation
      device_limit
    }
  }
`;

export const batchCreateLicensesMutation = gql`
  mutation batchCreateLicenses(
    $user_id: Int
    $owner_id: Int
    $device_limit: Int
    $product_ids: [Int]
    $date_of_activation: String
    $time_to_live: String
    $sendEmail: Boolean
    $resetPassword: Boolean
  ) {
    batchCreateLicenses(
      user_id: $user_id
      owner_id: $owner_id
      device_limit: $device_limit
      product_ids: $product_ids
      date_of_activation: $date_of_activation
      time_to_live: $time_to_live
      resetPassword: $resetPassword
      sendEmail: $sendEmail
    ) {
      id
      email
      first_name
      licenses {
        id
      }
      last_name
      avatar
    }
  }
`;

export const updateLicenseMutation = gql`
  mutation updateLicense(
    $id: Int!
    $time_to_live: String!
    $product_name: String!
    $user_email: String!
    $customer_email: String!
    $date_of_activation: String
    $device_limit: Int
  ) {
    updateLicense(
      id: $id
      time_to_live: $time_to_live
      product_name: $product_name
      user_email: $user_email
      customer_email: $customer_email
      date_of_activation: $date_of_activation
      device_limit: $device_limit
    ) {
      id
      time_to_live
      product {
        id
      }
      user {
        id
      }
      customer {
        id
      }
      date_of_activation
      device_limit
    }
  }
`;

export const deleteLicenseMutation = gql`
  mutation deleteLicense($id: Int!) {
    deleteLicense(id: $id) {
      id
    }
  }
`;
