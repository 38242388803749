import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: 'rgb(87, 192, 49)',
      dark: 'rgb(85, 187, 47)',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: 'rgb(255,104,57)',
      dark: 'rgb(242, 99, 55)',
      contrastText: '#fff',
    },
  },
});

export const colors = {
  // COLORS
  blue: 'rgb(47,155,239)',
  dark_blue: 'rgb(31, 65, 92)',
  yellow: 'rgb(255,172,45)',
  red: 'rgb(255,104,57)',
  green: 'rgb(87, 192, 49)',
  purple: 'rgb(162 93 215)',

  white: 'rgb(255, 255, 255)',
  light_grey: 'rgb(243,243,243)',
  grey: 'rgb(237, 237, 237)',
  semi_dark_grey: '#9B9B9B',
  dark_grey: 'rgb(115, 142, 164)',
};

// APP ENTITIES
colors['font'] = colors.white;
colors['dark_font'] = colors.dark_blue;
colors['light_bg'] = colors.light_grey;
colors['separator'] = colors.grey;

export const sizes = {
  buttons: {
    small: '24px',
    default: '50px',
    large: '40px',
  },
  icons: {
    small: '24px',
    default: '32px',
    large: '40px',
  },
  fonts: {
    size: [12, 14, 16, 20, 24, 30, 38, 46, 56, 68],
  },
  lineHeights: {
    size: [20, 22, 24, 28, 32, 38, 46, 54, 64, 76],
  },
};

sizes['fonts']['small'] = sizes.fonts.size[1] + 'px';
sizes['lineHeights']['small'] = sizes.lineHeights.size[1] + 'px';

sizes['fonts']['default'] = sizes.fonts.size[3] + 'px';
sizes['lineHeights']['default'] = sizes.lineHeights.size[3] + 'px';

sizes['fonts']['large'] = sizes.fonts.size[5] + 'px';
sizes['lineHeights']['large'] = sizes.lineHeights.size[5] + 'px';
