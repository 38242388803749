// react
import { useState, useReducer, useEffect } from 'react';
import { register, getUserDetails } from '../../data';

/** component does whaaat? */
export function useRegister(values) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useReducer(errorReducer, null);
  const [token, setToken] = useState(null);
  const [data, setData] = useReducer(dataReducer, null);

  function errorReducer(state, error) {
    setLoading(false);
    return error;
  }

  function dataReducer(state, data) {
    setLoading(false);
    return data;
  }

  function reset() {
    setLoading(false);
    setError(null);
    setToken(null);
    setData(null);
  }

  useEffect(() => {
    if (!!values) {
      setLoading(true);
      const data = new FormData();
      data.set('first_name', values.first_name.trim());
      data.set('last_name', values.last_name.trim());
      data.set('email', values.email.trim());
      data.set('password', values.password);
      data.set('c_password', values.c_password);
      register(data)
        .then((token) => {
          setToken(token);
          getUserDetails(token).then(setData).catch(setError);
        })
        .catch(setError);
    } else {
      reset();
    }
  }, [values]);

  return [loading, error, data, token];
}
