import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

export default createGlobalStyle`
	${normalize()};
	html, body, div#root {
		width: 100%;
		height: 100%;
		
		padding: 0;
		margin: 0;
	}
	
	* {
		font-family: 'Montserrat', sans-serif;
		font-weight: 400;
	}

	.MuiListItem-root.Mui-focusVisible {
		background-color: transparent !important;
	}
	a.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected {
		background-color: #59C11A !important;
		color: white !important;
		.MuiSvgIcon-root {
			fill: white;
		}

	}
	a.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected.product {
		background-color: rgb(47,155,239) !important;
	}

	a.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected.roles {
		background-color: #ffac1b !important;
	}

	a.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected.groups {
		background-color: rgb(162 93 215) !important;
	}

	a.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected.institutions {
		background-color: rgb(31, 65, 92) !important;
	}

	.MuiDrawer-paper ul.MuiList-root.MuiList-padding {
		padding: 0;
	}
`;
