// react
import React from 'react';
// styles
import styled from 'styled-components';
// import classNames from 'classnames'
import { colors } from '../../variables/css';
import Spinner from '../../components/icons/loading';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding: 10px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${colors.light_grey};
`;

/** component does whaaat? */
function Loading({ ...otherProps }) {
  return (
    <Wrapper>
      <Spinner width="50px" fill={colors.blue} {...otherProps} />
    </Wrapper>
  );
}

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
