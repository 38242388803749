// todo: icon left and right
// react
import React from 'react';
import PropTypes from 'prop-types';
// styles
import styled from 'styled-components';
import classNames from 'classnames';
import { colors, sizes } from '../../variables/css';

const { buttons, fonts } = sizes;

const Wrapper = styled.button`
  min-width: min-content;
  min-height: 40px;

  box-sizing: border-box;
  padding: 0 20px;
  border: none;
  border-radius: ${buttons['default']};

  display: flex;
  justify-content: center;
  align-items: center;

  white-space: nowrap;

  background: none;

  > svg {
    margin: 5px;
  }

  &.is-block {
    width: 100%;
  }

  :hover {
    cursor: pointer;
  }
`;

const Name = styled.span`
  color: ${({ color }) => color};
  font-size: ${fonts['default']};
  letter-spacing: 3px;
`;

/** component does whaaat? */
function Component({
  isBlock,
  size,
  color,
  IconLeft,
  IconRight,
  children: name,
  ...otherProps
}) {
  return (
    <Wrapper
      type="button"
      {...otherProps}
      className={classNames({ 'is-block': isBlock }, `size-${size}`)}>
      {!!IconLeft && <IconLeft width="25px" fill={color} />}
      <Name className={`size-${size}`} color={color}>
        {name.toUpperCase()}
      </Name>
      {!!IconRight && <IconRight width="25px" fill={color} />}
    </Wrapper>
  );
}

Component.propTypes = {
  size: PropTypes.oneOf(['large', 'default', 'small']),
  color: PropTypes.string,
};

Component.defaultProps = {
  size: 'default',
  color: colors.blue,
};

export default Component;
