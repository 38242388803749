import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './data/graphql';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import Routes from './routes';

function App() {
  return (
    <CookiesProvider>
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={client}>
          <Router>
            <Routes />
          </Router>
        </ApolloProvider>
      </I18nextProvider>
    </CookiesProvider>
  );
}

export default App;
