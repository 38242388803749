import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { updateUserMutation } from '../../graphql/user';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik, Form as FormikForm } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress } from '@material-ui/core';

const Form = styled(FormikForm)`
  .formik-field-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiTextField-root.MuiFormControl-root {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }
`;

function UserEditForm({ handleClose, selectedUser, groupsData, institutions }) {
  const [isFetching, setIsFetching] = useState(false);

  const [updateUserData] = useMutation(updateUserMutation);
  const validateForm = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Feld wird benötigt';
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        values.email,
      )
    ) {
      errors.email = 'Ungültige E-Mail Adresse';
    }
    if (values.first_name.trim().length < 1) {
      errors.first_name = 'Feld wird benötigt';
    }
    if (values.last_name.trim().length < 1) {
      errors.last_name = 'Feld wird benötigt';
    }
    if (
      values.password.trim().length < 8 &&
      values.password.trim().length > 0
    ) {
      errors.password = 'Mindestens 8 Zeichen.';
    }

    return errors;
  };

  const submitForm = async (values) => {
    setIsFetching(true);
    const {
      id,
      last_name,
      first_name,
      email,
      password,
      group_id: group,
      institution_id: institution,
    } = values;

    let institution_id = institution;
    let group_id = group;

    if (institution_id !== '') {
      institution_id = institutions.find(
        (ins) => ins.institution_name === institution_id,
      ).id;
    } else {
      group_id = '';
    }
    let variables = {
      id,
      first_name,
      last_name,
      email,
      group_id,
      institution_id,
    };
    if (!!password) {
      variables.password = password;
    }

    updateUserData({ variables })
      .then((response) => {
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        setIsFetching(false);
      });
  };

  const initialUser = {
    id: selectedUser.id,
    email: selectedUser.email,
    first_name: selectedUser.first_name,
    last_name: selectedUser.last_name,
    password: '',
    group_id: !!selectedUser.group ? selectedUser.group.id : '',
    institution_id: !!selectedUser.institution
      ? selectedUser.institution.institution_name
      : '',
  };
  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ paddingTop: '15px' }}>
        Benutzer bearbeiten
      </DialogTitle>
      <Formik
        initialValues={initialUser}
        validate={validateForm}
        validateOnBlur={true}
        onSubmit={submitForm}
        render={({ values, errors, handleChange }) => {
          const {
            last_name,
            first_name,
            password,
            email,
            group_id,
            institution_id,
          } = values;

          const displayedGroups = groupsData.filter((group) =>
            !!group.institution
              ? group.institution.institution_name === institution_id
              : false,
          );

          return (
            <Form>
              <DialogContent style={{ paddingTop: 0 }}>
                <FormControl style={{ width: '100%', marginBottom: '20px' }}>
                  <Autocomplete
                    id="institution_id"
                    options={institutions}
                    name="institution_id"
                    getOptionLabel={(option) => `${option.institution_name}`}
                    renderInput={(params) => (
                      <TextField {...params} label="Institution" />
                    )}
                    defaultValue={
                      !!institution_id
                        ? institutions.find(
                            (ins) => ins.institution_name === institution_id,
                          )
                        : null
                    }
                    onInputChange={(event, newInputValue) => {
                      handleChange({
                        ...newInputValue,
                        target: {
                          name: 'institution_id',
                          value: newInputValue,
                        },
                      });
                      if (!!newInputValue) {
                        handleChange({
                          ...newInputValue,
                          target: {
                            name: 'group_id',
                            value: '',
                          },
                        });
                      }
                    }}
                  />
                </FormControl>
                {institution_id && (
                  <FormControl style={{ width: '100%', marginBottom: '20px' }}>
                    <InputLabel id="group-select-label">
                      {!!displayedGroups.length
                        ? 'Gruppe'
                        : 'Keine Gruppe gefunden'}
                    </InputLabel>
                    <Select
                      labelId="group-select-label"
                      value={group_id}
                      name="group_id"
                      disabled={!displayedGroups.length > 0}
                      onChange={handleChange}>
                      {!!displayedGroups.length && (
                        <MenuItem value="">Auswahl entfernen</MenuItem>
                      )}

                      {displayedGroups.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <div
                  className="formik-field-wrapper"
                  style={{ marginBottom: '20px' }}>
                  <TextField
                    error={!!errors.first_name}
                    helperText={!!errors.first_name && errors.first_name}
                    name="first_name"
                    label="Vorname *"
                    value={first_name}
                    onChange={handleChange}
                  />
                  <TextField
                    error={!!errors.last_name}
                    helperText={!!errors.last_name && errors.last_name}
                    name="last_name"
                    label="Nachname *"
                    value={last_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="formik-field-wrapper">
                  <TextField
                    error={!!errors.email}
                    helperText={!!errors.email && errors.email}
                    name="email"
                    label="E-Mail *"
                    type="email"
                    value={email}
                    onChange={handleChange}
                  />
                  <TextField
                    error={!!errors.password}
                    helperText={
                      !!errors.password
                        ? errors.password
                        : 'Mindestens 8 Zeichen.'
                    }
                    name="password"
                    label="Passwort"
                    type="password"
                    value={password}
                    onChange={handleChange}
                  />
                </div>
              </DialogContent>
              <DialogActions style={{ padding: '25px 20px' }}>
                {isFetching ? (
                  <CircularProgress style={{ width: '20px', height: '20px' }} />
                ) : (
                  <>
                    <Button color="default" onClick={handleClose}>
                      Abbrechen
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disableElevation>
                      Speichern
                    </Button>
                  </>
                )}
              </DialogActions>
            </Form>
          );
        }}></Formik>
    </Dialog>
  );
}
UserEditForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedUser: PropTypes.object.isRequired,
  groupsData: PropTypes.array.isRequired,
  institutions: PropTypes.array.isRequired,
};

export default UserEditForm;
