import { combineReducers } from 'redux';
import { SIGN_IN, SIGN_OUT } from '../types';

function id(state = null, action) {
  switch (action.type) {
    case SIGN_IN:
      return action.user.id;
    case SIGN_OUT:
      return null;
    default:
      return state;
  }
}

function firstName(state = '', action) {
  switch (action.type) {
    case SIGN_IN:
      return action.user.first_name;
    case SIGN_OUT:
      return '';
    default:
      return state;
  }
}

function lastName(state = '', action) {
  switch (action.type) {
    case SIGN_IN:
      return action.user.last_name;
    case SIGN_OUT:
      return '';
    default:
      return state;
  }
}

function email(state = '', action) {
  switch (action.type) {
    case SIGN_IN:
      return action.user.email;
    case SIGN_OUT:
      return '';
    default:
      return state;
  }
}

function authenticationToken(state = '', action) {
  switch (action.type) {
    case SIGN_IN:
      return action.token;
    case SIGN_OUT:
      return '';
    default:
      return state;
  }
}

export default combineReducers({
  id,
  firstName,
  lastName,
  email,
  authenticationToken,
});
