import Users from '../users';
import Products from '../../pages/product/list';
import AddUser from '../addUser';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AppsIcon from '@material-ui/icons/Apps';
// import LockIcon from '@material-ui/icons/Lock';
import { RoleList } from '../../pages/role/list';
import GroupList from '../../pages/group/list';
import InstitutionList from '../../pages/institution/list';
import Role from '../../pages/role/show';

export const routes = [
  { title: 'Benutzer', link: '/user', icon: PeopleIcon, component: Users },
  { title: 'Produkte', link: '/products', icon: AppsIcon, component: Products },
  // { title: 'Rollen', link: '/roles', icon: LockIcon, component: RoleList },
  {
    title: 'Gruppen',
    link: '/groups',
    icon: GroupWorkIcon,
    component: GroupList,
  },
  {
    title: 'Institutionen',
    link: '/institutions',
    icon: AccountBalanceIcon,
    component: InstitutionList,
  },

  /*{ title: "Produkt", link: "/product/:id", component: Product },
    { title: "Nutzer Übersicht", link: "/user/:id", component: User },
    { title: "Profil", link: "/profile", icon: PersonIcon, component: Profile },*/
  { title: 'Produkt hinzufügen', link: '/product-add', component: Products },
  { title: 'Rolle hinzufügen', link: '/role-add', component: RoleList },
  { link: '/add-user', component: Users },
];

export const sidepanelRoutes = [
  { title: 'Rolle hinzufügen', link: '/role-add', component: Role },
  { link: '/add-user', component: AddUser },
];
