import React from 'react';

export default (props) => (
  <svg viewBox="0 0 100 100" {...props}>
    <rect
      x="0"
      y="0"
      width="12"
      height="100"
      rx="5"
      transform="translate(44, 0)"
    />
    <rect
      x="0"
      y="0"
      width="100"
      height="12"
      rx="5"
      transform="translate(0, 44)"
    />
  </svg>
);
