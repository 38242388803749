import gql from 'graphql-tag';

const getGroups = gql`
  query getGroups($query: String, $page: Int) {
    groupSearch(query: $query, page: $page) {
      totalCount
      page
      data {
        id
        name
        institution {
          id
          institution_name
        }
      }
    }
  }
`;

const getAllGroups = gql`
  query getGroups($limit: Int) {
    groups(limit: $limit) {
      id
      name
      institution {
        id
        institution_name
      }
    }
  }
`;

const createGroupMutation = gql`
  mutation createGroup($name: String!, $institution_id: Int) {
    createGroup(name: $name, institution_id: $institution_id) {
      id
      name
      institution {
        id
        institution_name
      }
    }
  }
`;

const updateGroupMutation = gql`
  mutation updateGroup($id: Int!, $name: String, $institution_id: Int) {
    updateGroup(id: $id, name: $name, institution_id: $institution_id) {
      id
      name
      institution {
        id
        institution_name
      }
      __typename
    }
  }
`;

const deleteGroupMutation = gql`
  mutation deleteGroup($id: Int!) {
    deleteGroup(id: $id)
  }
`;
export {
  getGroups,
  getAllGroups,
  createGroupMutation,
  updateGroupMutation,
  deleteGroupMutation,
};
