import gql from 'graphql-tag';

export const getInstitutions = gql`
  query getGroups($query: String, $page: Int) {
    institutionSearch(query: $query, page: $page) {
      totalCount
      page
      data {
        id
        institution_name
        first_name
        last_name
        vat_id
        tax_id
        contact_email
        address {
          street
          number
          additional
          postal_code
          city
          country
        }
      }
    }
  }
`;

export const getAllInstitutions = gql`
  query getInstitutions($limit: Int) {
    institutions(limit: $limit) {
      id
      institution_name
      first_name
      last_name
      vat_id
      tax_id
      address {
        street
        number
        additional
        postal_code
        city
        country
      }
    }
  }
`;

export const updateInstitutionMutation = gql`
  mutation updateInstitution(
    $id: Int!
    $institution_name: String
    $first_name: String
    $last_name: String
    $vat_id: String
    $tax_id: String
    $address_street: String
    $address_number: String
    $address_postal_code: String
    $address_country: String
    $address_city: String
    $address_additional: String
    $leader_id: Int
    $contact_email: String
  ) {
    updateInstitution(
      id: $id
      institution_name: $institution_name
      first_name: $first_name
      last_name: $last_name
      vat_id: $vat_id
      tax_id: $tax_id
      address_street: $address_street
      address_number: $address_number
      address_postal_code: $address_postal_code
      address_country: $address_country
      address_city: $address_city
      address_additional: $address_additional
      leader_id: $leader_id
      contact_email: $contact_email
    ) {
      id
      institution_name
    }
  }
`;

export const createInstitutionMutation = gql`
  mutation createInstitution(
    $institution_name: String!
    $first_name: String
    $last_name: String
    $vat_id: String
    $tax_id: String
    $address_street: String
    $address_number: String
    $address_postal_code: String
    $address_country: String
    $address_city: String
    $address_additional: String
    $leader_id: Int
    $contact_email: String
  ) {
    createInstitution(
      institution_name: $institution_name
      first_name: $first_name
      last_name: $last_name
      vat_id: $vat_id
      tax_id: $tax_id
      address_street: $address_street
      address_number: $address_number
      address_postal_code: $address_postal_code
      address_country: $address_country
      address_city: $address_city
      address_additional: $address_additional
      leader_id: $leader_id
      contact_email: $contact_email
    ) {
      id
      institution_name
      first_name
      last_name
      vat_id
      tax_id
      contact_email
      address {
        street
        additional
        postal_code
        city
        country
      }
    }
  }
`;

export const deleteInstitutionMutation = gql`
  mutation deleteInstitution($id: Int!) {
    deleteInstitution(id: $id)
  }
`;
