// react
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// styles
import styled from 'styled-components';
import { colors } from '../../variables/css';
// hoc
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
// components
import Loading from '../loading';
import Input from '../../components/dataEntry/input/default';
import Button from '../../components/buttons/primary';
import Error from '../../containers/error';
import Appucations from '../../components/icons/appucations';
// data
import { useCookies } from 'react-cookie';
import { signIn } from '../../redux/actions';
import { useRegister } from './useRegister';
// helper
import * as Yup from 'yup';

const Wrapper = styled(Form)`
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > * {
    margin: 20px 0;
  }

  > svg {
    width: 100px;
  }
`;

const Heading = styled.h1`
  color: ${colors.dark_blue};
  font-size: 30px;
  text-align: center;
`;

const initialValues = {
  password: '',
  c_password: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, 'Dein Passwort sollte mindestens 5 Zeichen lang sein')
    .required('Passwort ist ein Pflichtfeld'),
  c_password: Yup.string()
    .when('password', {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Deiner Passwörter sind ungleich',
      ),
    })
    .required('Passwort Kontrolle ist ein Pflichtfeld'),
});

/**  */
export function ResetPassword({ signIn, history, ...otherProps }) {
  const [values, setValues] = useState(null);
  const [loading, error, user, token] = useRegister(values);
  const [cookies, setCookie] = useCookies(['auth-token']);

  useEffect(() => {
    if (!!user && !!token) {
      setCookie('auth-token', token, { maxAge: 864000 });
      // set data in redux
      signIn({ user, token });
      // redirect
      history.push('/');
    }
  }, [user]);

  function submit(values, { setSubmitting }) {
    setValues(values);
    setSubmitting(false);
  }

  function reset() {
    setValues(null);
  }

  function handleRedirection() {
    history.push('/login');
  }

  if (loading) return <Loading />;

  if (error)
    return (
      <Error
        title="Registrierung Fehlgeschlagen"
        description="Bei der Registrierung ist etwas schief gelaufen. Bitte prüfe deine Angaben erneut und versuche es noch einmal."
        onAccept={reset}
      />
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={submit}
      render={({ values, errors, handleChange, handleSubmit }) => {
        return (
          <Wrapper {...otherProps}>
            <Appucations />
            <Heading>Passwort Zurücksetzen</Heading>
            <Input
              name="password"
              type="password"
              placeholder="Passwort"
              value={values['password']}
              onChange={handleChange}
            />
            <Input
              name="c_password"
              type="password"
              placeholder="Passwort Kontrolle"
              value={values['c_password']}
              onChange={handleChange}
            />
            <Button type="submit" onClick={handleSubmit}>
              Speichern
            </Button>
          </Wrapper>
        );
      }}
    />
  );
}

ResetPassword.propTypes = {
  signIn: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

ResetPassword.defaultProps = {};

export default connect(null, { signIn })(withRouter(ResetPassword));
