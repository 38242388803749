import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import { colors } from '../../variables/css';

const Form = styled(FormikForm)`
  padding: 0 24px;
`;

const Name = styled.h1`
  color: ${colors.dark_blue};
`;

const Group = styled.section`
  width: 100%;

  box-sizing: border-box;
  padding: 20px 0;

  > h2 {
    margin-top: 0;

    box-sizing: border-box;
    padding: 0 20px;
    border-bottom: 1px solid ${colors.semi_dark_grey};

    font-size: 18px;
    text-align: left;
  }
`;

const Content = styled.ul`
  width: 100%;

  margin: 0;

  box-sizing: border-box;
  padding: 0 20px;

  display: grid;
  grid-gap: 5px;
`;

const Buttons = styled.section`
  width: 100%;

  box-sizing: border-box;
  padding: 50px 20px 0 20px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  > * {
    margin: 5px 0;
  }
`;

export { Form, Name, Group, Content, Buttons };
