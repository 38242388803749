// in utils: here go all future helper functions and global hooks
export function customHandleChange(name, handleChange, e) {
  handleChange({ ...e, target: { ...e.target, name } });
}

export function findUserEmail(value, users) {
  if (!value) return '';
  let user = users.find((user) => user.id === value);
  return user ? user.email : '';
}

export function findProductName(value, products) {
  if (!value) return '';
  let product = products.find((product) => product.name === value);
  return product ? product.name : '';
}
