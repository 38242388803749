// react
import React from 'react';
import PropTypes from 'prop-types';
// styles
import styled from 'styled-components';
import { colors } from '../../variables/css';
// components
import { Primary as Button } from '../../components/buttons';

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;

  box-sizing: border-box;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: ${colors.font};
  text-align: center;

  background: ${colors.dark_blue};

  > * {
    margin: 20px 0;
  }
`;

/** component does whaaat? */
function Component({
  title,
  description,
  buttonMessage,
  onAccept: accept,
  ...otherProps
}) {
  return (
    <Wrapper {...otherProps}>
      <h1>{title}</h1>
      <p>{description}</p>
      <Button color={colors.red} onClick={accept}>
        {buttonMessage}
      </Button>
    </Wrapper>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonMessage: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
};

Component.defaultProps = {
  title: 'Unbekannter Fehler',
  description: 'Oops, da is ein unbekannter Fehler aufgetreten.',
  buttonMessage: 'Ok',
  onAccept: PropTypes.func.isRequired,
};

export default Component;
