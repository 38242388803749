import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';

/*MATERIAL UI */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CircularProgress, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Form = styled(FormikForm)`
  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
  }

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -22px;
  }
`;

function GroupForm({
  handleClose,
  selectedGroup,
  handleSubmit,
  institutions,
  isFetching,
}) {
  const validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Feld wird benötigt';
    }
    return errors;
  };

  const initialGroup = {
    name: !!selectedGroup ? selectedGroup.name : '',
    institution_id:
      !!selectedGroup && !!selectedGroup.institution
        ? selectedGroup.institution.institution_name
        : null,
  };

  const handleSubmitForm = (values) => {
    if (!!values.institution_id) {
      const id = institutions.find(
        (ins) => ins.institution_name === values.institution_id,
      ).id;
      values.institution_id = id;
    }
    handleSubmit(values);
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ paddingTop: '15px' }}>
        Gruppe {!!selectedGroup ? 'bearbeiten' : 'hinzufügen'}
      </DialogTitle>
      <Formik
        initialValues={initialGroup}
        validate={validateForm}
        validateOnBlur={true}
        onSubmit={handleSubmitForm}
        render={({ values, errors, handleBlur, touched, handleChange }) => {
          const { name, institution_id } = values;

          return (
            <Form>
              <DialogContent>
                <TextField
                  error={!!touched.name && !!errors.name}
                  helperText={!!touched.name && !!errors.name && errors.name}
                  name="name"
                  label="Name"
                  value={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControl>
                  <Autocomplete
                    id="institution_id"
                    options={institutions}
                    name="institution_id"
                    onBlur={handleBlur}
                    getOptionLabel={(option) => `${option.institution_name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          !!touched.institution_id && !!errors.institution_id
                        }
                        helperText={
                          !!touched.institution_id &&
                          !!errors.institution_id &&
                          errors.institution_id
                        }
                        label="Institution"
                      />
                    )}
                    defaultValue={
                      !!institution_id
                        ? institutions.find(
                            (ins) => ins.institution_name === institution_id,
                          )
                        : null
                    }
                    onSelect={(e) => {
                      handleChange({
                        ...e,
                        target: {
                          name: 'institution_id',
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions style={{ padding: '25px 20px' }}>
                {isFetching ? (
                  <CircularProgress style={{ width: '20px', height: '20px' }} />
                ) : (
                  <>
                    <Button color="default" onClick={handleClose}>
                      Abbrechen
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disableElevation>
                      Speichern
                    </Button>
                  </>
                )}
              </DialogActions>
            </Form>
          );
        }}></Formik>
    </Dialog>
  );
}
GroupForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  institutions: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default GroupForm;
