import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { LinearProgress } from '@material-ui/core';

/* CUSTOM */
import {
  createInstitutionMutation,
  deleteInstitutionMutation,
  getAllInstitutions,
  getInstitutions,
  updateInstitutionMutation,
} from '../../../graphql/institution';
import ConfirmationDialog from '../../../components/helpers/ConfirmationDialog';
import InstitutionForm from '../components/InstitutionForm';
import AsyncTable from '../../../components/helpers/AsyncTable';

export function InstitutionsList({ user, history, ...otherProps }) {
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [openInstitutionDialog, setOpenInstitutionDialog] = useState(false);
  const [
    openDeleteInstitutionDialog,
    setOpenDeleteInstitutionDialog,
  ] = useState(false);

  const [deleteInstitution] = useMutation(deleteInstitutionMutation);
  const [updateInstitution] = useMutation(updateInstitutionMutation);
  const [createInstitution] = useMutation(createInstitutionMutation);
  let { refetch: refetchAllInstitutions } = useQuery(getAllInstitutions);

  let { loading: isLoading, data, refetch } = useQuery(getInstitutions, {
    variables: { query: '', page: 1 },
  });

  /* TABLE LOGIC */

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingSearch, setIsFetchingSearch] = useState(false);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const searchRef = useRef(searchValue);
  searchRef.current = searchValue;

  const handleEditInstitutionButton = (rowData) => {
    setSelectedInstitution(rowData);
    setOpenInstitutionDialog(true);
  };

  const handleDeleteInstitutionButton = (rowData) => {
    setSelectedInstitution(rowData);
    setOpenDeleteInstitutionDialog(true);
  };

  const handleClose = () => {
    setSelectedInstitution(null);
    setOpenInstitutionDialog(false);
    setOpenDeleteInstitutionDialog(false);
  };

  useEffect(() => {
    if (!isLoading && isFetchingSearch) setIsFetchingSearch(false);
  }, [isLoading]);

  const handleSearch = () => {
    setIsFetchingSearch(true);
    setPage(0);
    handleRefetchInstitutions({ query: searchRef.current, page: 1 });
  };

  const handlePageChange = (page) => {
    setPage(page);
    handleRefetchInstitutions({ query: searchRef.current, page: page + 1 });
  };

  const handleRefetchInstitutions = ({ query, page }) => {
    refetch({ query, page });
    refetchAllInstitutions();
  };

  const handleInstitutionSubmit = (values) => {
    setIsFetching(true);
    const id = !!selectedInstitution ? selectedInstitution.id : null;
    const {
      institution_name,
      first_name,
      last_name,
      vat_id,
      tax_id,
      address_street,
      address_number,
      address_country,
      address_postal_code,
      address_city,
      address_additional,
      contact_email,
      //   leader_id TBD,
    } = values;
    handleClose();
    const variables = {
      institution_name,
      first_name,
      last_name,
      vat_id,
      tax_id,
      address_street,
      address_number,
      address_city,
      address_country,
      address_postal_code,
      address_additional,
      contact_email,
      //   leader_id,
    };

    if (!id) {
      createInstitution({ variables })
        .then((res) => {
          handleRefetchInstitutions({ query: searchRef.current, page: 1 });
          setIsFetching(false);
        })
        .catch((e) => console.log(e));
    } else {
      variables.id = id;
      updateInstitution({ variables })
        .then(() => {
          setIsFetching(false);
          handleRefetchInstitutions({ query: searchRef.current, page: 1 });
        })
        .catch((e) => console.log(e));
    }
  };

  const handleDeleteInstitution = () => {
    setIsFetching(true);
    const { id } = selectedInstitution;
    handleClose();
    deleteInstitution({ variables: { id } })
      .then(() => {
        handleRefetchInstitutions({ query: searchRef.current, page: 1 });
        setIsFetching(false);
      })
      .catch((e) => console.log(e));
  };

  const columns = [
    { title: 'Name', field: 'institution_name' },
    {
      title: 'Adresse',
      sorting: false,
      render: (rowData) => (
        <>
          <p style={{ marginTop: 0 }}>
            {rowData.address.street} {rowData.address.number}
          </p>
          <p style={{ marginTop: 5 }}>
            {rowData.address.postal_code} {rowData.address.city}
          </p>
        </>
      ),
    },
    { title: 'Land', field: 'address.country' },
  ];

  return (
    <div className={classNames({ 'is-loading': isLoading })} {...otherProps}>
      {!data ? (
        <LinearProgress />
      ) : (
        <>
          <AsyncTable
            handleAdd={() => setOpenInstitutionDialog(true)}
            handleEditItem={handleEditInstitutionButton}
            handleDeleteItem={handleDeleteInstitutionButton}
            title="Institutionen"
            columns={columns}
            data={data && data.institutionSearch && data.institutionSearch.data}
            total={
              data &&
              data.institutionSearch &&
              data.institutionSearch.totalCount
            }
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            page={page}
            pageSize={100}
            isFetching={isFetchingSearch}
            handlePageChange={handlePageChange}
          />
          {openDeleteInstitutionDialog && (
            <ConfirmationDialog
              title="Soll die Institution unwiderruflich gelöscht werden?"
              discardText="Abbrechen"
              discardColor="default"
              discardAction={handleClose}
              confirmText="Ja, löschen"
              confirmColor="secondary"
              isFetching={isFetching}
              confirmAction={handleDeleteInstitution}
            />
          )}
          {openInstitutionDialog && (
            <InstitutionForm
              selectedInstitution={selectedInstitution}
              handleClose={handleClose}
              isFetching={isFetching}
              handleSubmit={handleInstitutionSubmit}
            />
          )}
        </>
      )}
    </div>
  );
}

function withRedux({ user }) {
  return { user };
}

export default connect(withRedux, null)(withRouter(InstitutionsList));
