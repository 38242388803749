import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import Dialog from '@material-ui/core/Dialog';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import '../../css/list.css';

import {
  batchCreateLicensesMutation,
  updateLicenseMutation,
} from '../../graphql/license';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const CheckboxLabel = styled.p`
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
`;


const Form = styled(FormikForm)`
  .formik-field-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiTextField-root.MuiFormControl-root {
      width: calc(50% - 10px);
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
  }

  .MuiAutocomplete-root {
    .MuiFormControl-root {
      margin: 0;
    }
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -22px;
  }
  h3 {
    margin: 10px 0 20px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
  }
  .MuiInput-underline.Mui-error:after {
    transform: scaleX(1);
    border-bottom-color: #f44336;
  }
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
  }
`;

const LicensesForm = ({
  selectedLicense,
  selectedUser,
  handleClose,
  userData,
  productData,
}) => {
  const [showProductSelection, setShowProductSelection] = useState(false);
  const [batchCreateLicenses] = useMutation(batchCreateLicensesMutation);
  const [updateLicense] = useMutation(updateLicenseMutation);
  const [sendEmail, setSendEmail] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)

  const handleSendEmail = (event) => {
    setSendEmail(event.target.checked)
  }

  const handleResetPassword = (event) => {
    setResetPassword(event.target.checked)
  }


  const validateForm = (values) => {
    const errors = {};

    if (!values.owner_id) {
      errors.owner_id = 'Feld wird benÃ¶tigt';
    } else {
      const foundOwner = userData.find(
        (owner) => values.owner_id === owner.email,
      ).id;
      if (!foundOwner) errors.owner_id = 'Nutzer nicht gefunden.';
    }
    if (!values.device_limit) {
      errors.device_limit = 'Feld wird benÃ¶tigt';
    }
    if (!values.license) {
      errors.products = 'Feld wird benÃ¶tigt';
    }
    if (
      values.time_to_live < values.date_of_activation ||
      isNaN(values.time_to_live.getTime())
    ) {
      errors.time_to_live = true;
    }
    if (isNaN(values.date_of_activation.getTime())) {
      errors.date_of_activation = true;
    }

    return errors;
  };

  const submitForm = async (values) => {
    const {
      owner_id,
      license,
      date_of_activation,
      time_to_live,
      device_limit,
    } = values;

    const variables = {
      device_limit,
      sendEmail,
      resetPassword,
      date_of_activation,
      time_to_live,
    };
    let dataResult;
    const customer = userData.find((customer) => customer.email === owner_id);

    if (!!selectedLicense) {
      const editVariables = {
        ...variables,
        id: selectedLicense.id,
        product_name: productData.products.find(
          (product) => product.id === license,
        ).name,
        user_email: selectedUser.email,
        customer_email: customer.email,
      };
      dataResult = await updateLicense({ variables: editVariables });
    } else {
      const createVariables = {
        ...variables,
        user_id: selectedUser.id,
        owner_id: customer.id,
        product_ids: license,
      };
      dataResult = await batchCreateLicenses({ variables: createVariables });
    }
    if (dataResult && dataResult.data) {
      handleClose();
    }
  };

  function findLicense() {
    if (!!selectedLicense) {
      const foundLicense = selectedUser.licenses.find(
        (item) => item.id === selectedLicense.id,
      ).product;
      return !!foundLicense ? foundLicense.id : null;
    } else {
      return [];
    }
  }

  const initialData = {
    license: findLicense(),
    owner_id: !!selectedLicense
      ? selectedLicense.customer.email
      : selectedUser.email,
    user_id: selectedUser.id,
    device_limit: !!selectedLicense ? selectedLicense.device_limit : 3,
    date_of_activation: !!selectedLicense
      ? new Date(selectedLicense.date_of_activation)
      : new Date(),
    time_to_live: !!selectedLicense
      ? new Date(selectedLicense.time_to_live)
      : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  };

  return (
    <Dialog
      className="licence-form"
      open
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle style={{ paddingTop: '15px' }}>
        Lizenz {!!selectedLicense ? 'bearbeiten' : 'hinzufügen'} für{' '}
        {selectedUser.email}
      </DialogTitle>
      <Formik
        initialValues={initialData}
        validate={validateForm}
        validateOnBlur={true}
        onSubmit={submitForm}
        render={({ values, errors, handleBlur, touched, handleChange }) => {
          const {
            license,
            owner_id,
            device_limit,
            date_of_activation,
            time_to_live,
          } = values;

          const userLicenses = [];
          selectedUser.licenses.map((item) => {
            if (!!item.product) userLicenses.push(item.product.id);
          });

          const availableProducts = productData.products.filter((item) => {
            if (item.id === license) return true;
            return !(userLicenses.indexOf(item.id) !== -1);
          });
          return (
            <Form>
              <DialogContent style={{ paddingTop: '10px' }}>
                <h3>Zugehörigkeit</h3>
                <FormControl>
                  <InputLabel id="select-user-label">Benutzer</InputLabel>
                  <Select labelId="type-select-label" disabled value="1">
                    <MenuItem value="1">{selectedUser.email}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl style={{ marginBottom: '20px' }}>
                  <Autocomplete
                    id="owner_id"
                    options={userData}
                    name="owner_id"
                    onBlur={handleBlur}
                    getOptionLabel={(option) => `${option.email}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!touched.owner_id && !!errors.owner_id}
                        helperText={
                          !!touched.owner_id &&
                          !!errors.owner_id &&
                          errors.owner_id
                        }
                        label="Käufer *"
                        margin="normal"
                      />
                    )}
                    defaultValue={
                      !!owner_id
                        ? userData.find((owner) => owner.email === owner_id)
                        : ''
                    }
                    onSelect={(e) => {
                      handleChange({
                        ...e,
                        target: { name: 'owner_id', value: e.target.value },
                      });
                    }}
                  />
                </FormControl>
                {!selectedLicense &&
                <>
                <CheckboxLabel>
                  <Checkbox
                    color="default"
                    checked={sendEmail}
                    onChange={handleSendEmail}
                    name="pwfield"
                  />
                  Einladung per E-Mail Senden
                </CheckboxLabel>

                {sendEmail &&
                <CheckboxLabel>
                  <Checkbox
                    color="default"
                    checked={resetPassword}
                    onChange={handleResetPassword}
                    name="pwfield"
                  />
                  Passwort generieren
                </CheckboxLabel>

                }
                 </>
              }
              </DialogContent>
              <DialogContent style={{ paddingTop: 0 }}>
                <h3>Produktinformationen</h3>
                <div className="formik-field-wrapper">
                  {!!selectedLicense ? (
                    <FormControl
                      style={{ width: 'calc(70% - 10px)' }}
                      error={!!errors.license}>
                      <InputLabel id="products-single-label">
                        Produkt
                      </InputLabel>
                      <Select
                        labelId="products-single-label"
                        name="license"
                        onBlur={handleBlur}
                        value={license}
                        onChange={handleChange}>
                        {availableProducts.map((product) => (
                          <MenuItem key={product.id} value={product.id}>
                            {product.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!errors.license && (
                        <FormHelperText>{errors.license}</FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl
                      style={{ width: 'calc(70% - 10px)' }}
                      error={!!errors.license}>
                      <InputLabel id="products-label">Produkt/e</InputLabel>
                      <Select
                        labelId="products-label"
                        name="license"
                        multiple
                        onOpen={() => setShowProductSelection(true)}
                        onClose={() => setShowProductSelection(false)}
                        open={!!showProductSelection}
                        value={license}
                        onChange={handleChange}>
                        <MenuItem value="dummy-button">
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setShowProductSelection(false);
                            }}>
                            Übernehmen
                          </Button>
                        </MenuItem>

                        {availableProducts.map((product) => (
                          <MenuItem key={product.id} value={product.id}>
                            {product.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    style={{ width: 'calc(30% - 10px)' }}
                    onBlur={handleBlur}
                    error={!!touched.device_limit && !!errors.device_limit}
                    helperText={
                      !!touched.device_limit &&
                      !!errors.device_limit &&
                      errors.device_limit
                    }
                    name="device_limit"
                    label="Gerätelimit"
                    value={device_limit}
                    type="number"
                    onChange={handleChange}
                  />
                </div>

                <div className="formik-field-wrapper">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      label="Lizenzstart"
                      value={date_of_activation}
                      autoOk
                      onChange={(value) =>
                        handleChange({
                          target: { value, name: `date_of_activation` },
                        })
                      }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      name={`date_of_activation`}
                      invalidDateMessage="UngÃ¼ltiges Datum."
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      label="Lizenzende"
                      value={time_to_live}
                      autoOk
                      onChange={(value) => {
                        handleChange({
                          target: { value, name: `time_to_live` },
                        });
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      name={`time_to_live`}
                      minDate={new Date(date_of_activation).setDate(
                        date_of_activation.getDate() + 1,
                      )}
                      invalidDateMessage="Ungültiges Datum."
                      minDateMessage="Datum muss nach Aktivierung liegen."
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </DialogContent>
              <DialogActions style={{ padding: '25px 20px' }}>
                <Button color="default" onClick={handleClose}>
                  Abbrechen
                </Button>
                <Button
                  color="primary"
                  disabled={!selectedLicense && !license.length}
                  variant="contained"
                  type="submit"
                  disableElevation>
                  Speichern
                </Button>
              </DialogActions>
            </Form>
          );
        }}></Formik>
    </Dialog>
  );
};

LicensesForm.propTypes = {
  selectedUser: PropTypes.object,
  selectedLicense: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  userData: PropTypes.array.isRequired,
  productData: PropTypes.object.isRequired,
};

export default LicensesForm;
