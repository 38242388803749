import React, { useEffect, useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

export function Permission({ permissions, handleChange }) {
  const [expanded, setExpanded] = useState(false);
  const [width, setWidth] = useState(3);
  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    const widthRef = ref.current ? ref.current.offsetWidth : 0;
    if (widthRef <= 499) {
      setWidth(12);
    }
    if (widthRef >= 500) {
      setWidth(6);
    }
    if (widthRef >= 1000) {
      setWidth(3);
    }
  }, [ref]);

  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function customHandleChange(e, name, permission) {
    e = {
      ...e,
      target: {
        ...e.target,
        value: {
          ...permission,
          active: !permission.active,
        },
        name,
      },
    };
    handleChange(e);
  }

  return (
    <ExpansionPanel
      ref={ref}
      square
      expanded={expanded === 'permissions'}
      onChange={handleExpanded('permissions')}>
      <ExpansionPanelSummary
        aria-controls="panel1d-content"
        id="panel1d-header">
        <h3>Berechtigungen</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={1}>
          {permissions &&
            permissions.map((permission, index) => (
              <Grid item sm={width} key={permission.name}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={`permissions.${index}`}
                      checked={!!permissions[index].active}
                      onChange={(e) => {
                        customHandleChange(
                          e,
                          `permissions.${index}`,
                          permission,
                        );
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label={t(`PERMISSION_${permission.name}`)}
                />
              </Grid>
            ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
