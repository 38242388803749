// react
import React from 'react';
// styles
import styled from 'styled-components';
import classNames from 'classnames';
import { colors, sizes } from '../../../variables/css';

const { fonts } = sizes;

const Wrapper = styled.input`
  width: 100%;
  height: 30px;

  box-sizing: border-box;
  padding: 5px 3px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid ${colors.grey};

  color: ${colors.dark_blue};
  font-size: ${fonts.small};

  background: transparent;

  :hover,
  :focus {
    outline: none;
    border-color: ${colors.blue};
  }

  :focus {
    border-radius: 5px 5px 0 0;

    background: #fff;
  }
`;

function Component({ size, className, ...otherProps }) {
  return (
    <Wrapper
      className={classNames(className, `size-${size}`)}
      {...otherProps}
    />
  );
}

Component.propTypes = {};

Component.defaultProps = {};

export default Component;
