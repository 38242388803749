import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    de: {
      translations: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        password: 'Passwort',
        cPassword: 'Passwort bestätigen',
        registrationFailedHeadline: 'Registrierung unvollständig',
        registrationFailedDescription:
          'Deine Daten konnten nicht gesendet werden:',
        fieldIsRequired: '{{name}} ist ein Pflichtfeld',
        fieldIsNotAnEmail: '{{name}} ist keine gültige E-Mail-Adresse',
        fieldsAreNotMatching: '{{name1}} und {{name2}} stimmen nicht überein',
        fieldIsTooShort:
          '{{name}} ist zu kurz (mind. {{minimumCount}} Zeichen)',
        generalErrorTitle: 'Fehler',
        generalErrorDescription: 'Es ist ein allgemeiner Fehler aufgetreten:',
        PERMISSION_RESTORE_USER: 'Benutzer wiederherstellen',
        PERMISSION_UPDATE_PRODUCT_INSTRUCTIONS: 'Produktanweiungen bearbeiten',
        PERMISSION_SHOW_USER: 'Benutzer anzeigen (einzeln)',
        PERMISSION_SHOW_USERS: 'Benutzer anzeigen (alle)',
        PERMISSION_SEARCH_USERS: 'Benutzer suchen',
        PERMISSION_SHOW_ADDRESS: 'Adresse anzeigen',
        PERMISSION_SHOW_ADDRESSES: 'Adressen anzeigen',
        PERMISSION_SHOW_PRODUCT: 'Produkt anzeigen',
        PERMISSION_SHOW_PRODUCTS: 'Produkte anzeigen',
        PERMISSION_SHOW_LICENSE: 'Lizenz anzeigen',
        PERMISSION_SHOW_LICENSES: 'Lizenzen anzeigen',
        PERMISSION_SHOW_SCHOOL: 'Institution anzeigen',
        PERMISSION_SHOW_SCHOOLS: 'Institutionen anzeigen',
        PERMISSION_SHOW_GROUP: 'Gruppe anzeigen',
        PERMISSION_SHOW_GROUPS: 'Gruppen anzeigen',
        PERMISSION_SHOW_LEARNING_AREA: 'Lernbereich anzeigen',
        PERMISSION_SHOW_LEARNING_AREAS: 'Lernbereiche anzeigen',
        PERMISSION_SHOW_SUB_AREA: 'Unterbereich anzeigen',
        PERMISSION_SHOW_SUB_AREAS: 'Unterbereiche anzeigen',
        PERMISSION_SHOW_LEARNING_CONTENT: 'Lerninhalt anzeigen',
        PERMISSION_SHOW_LEARNING_CONTENTS: 'Lerninhalte anzeigen',
        PERMISSION_SHOW_QUESTION: 'Frage anzeigen',
        PERMISSION_SHOW_QUESTIONS: 'Fragen anzeigen',
        PERMISSION_SHOW_ANSWER: 'Antwort anzeigen',
        PERMISSION_SHOW_ANSWERS: 'Antworten anzeigen',
        PERMISSION_SHOW_SETTING: 'Einstellung anzeigen',
        PERMISSION_SHOW_SETTINGS: 'Einstellungen anzeigen',
        PERMISSION_CREATE_USER: 'Benutzer erstellen',
        PERMISSION_UPDATE_USER: 'Benutzer bearbeiten',
        PERMISSION_DELETE_USER: 'Benutzer löschen',
        PERMISSION_BATCH_CREATE_USER_WITH_LICENSE:
          'Mehrere Nutzer mit Lizenz erstellen',
        PERMISSION_CREATE_ADDRESS: 'Adresse hinzufügen',
        PERMISSION_UPDATE_ADDRESS: 'Adresse bearbeiten',
        PERMISSION_DELETE_ADDRESS: 'Adresse löschen',
        PERMISSION_CREATE_PRODUCT: 'Produkt erstellen',
        PERMISSION_UPDATE_PRODUCT: 'Produkt bearbeiten',
        PERMISSION_DELETE_PRODUCT: 'Produkt löschen',
        PERMISSION_CREATE_LICENSE: 'Lizenz erstellen',
        PERMISSION_UPDATE_LICENSE: 'Lizenz bearbeiten',
        PERMISSION_DELETE_LICENSE: 'Lizenz löschen',
        PERMISSION_CREATE_SCHOOL: 'Institution erstellen',
        PERMISSION_UPDATE_SCHOOL: 'Institution bearbeiten',
        PERMISSION_DELETE_SCHOOL: 'Institution löschen',
        PERMISSION_CREATE_LEARNING_AREA: 'Lernbereich erstellen',
        PERMISSION_UPDATE_LEARNING_AREA: 'Lernbereich bearbeiten',
        PERMISSION_DELETE_LEARNING_AREA: 'Lernbereich löschen',
        PERMISSION_CREATE_SUB_AREA: 'Unterbereich erstellen',
        PERMISSION_UPDATE_SUB_AREA: 'Unterbereich bearbeiten',
        PERMISSION_DELETE_SUB_AREA: 'Unterbereich löschen',
        PERMISSION_CREATE_LEARNING_CONTENT: 'Lerninhalt erstellen',
        PERMISSION_UPDATE_LEARNING_CONTENT: 'Lerninhalt bearbeiten',
        PERMISSION_DELETE_LEARNING_CONTENT: 'Lerninhalt löschen',
        PERMISSION_CREATE_QUESTION: 'Frage erstellen',
        PERMISSION_UPDATE_QUESTION: 'Frage bearbeiten',
        PERMISSION_DELETE_QUESTION: 'Frage löschen',
        PERMISSION_CREATE_ANSWER: 'Antwort erstellen',
        PERMISSION_UPDATE_ANSWER: 'Antwort bearbeiten',
        PERMISSION_DELETE_ANSWER: 'Antwort löschen',
        PERMISSION_CREATE_SETTING: 'Einstellung erstellen',
        PERMISSION_UPDATE_SETTING: 'Einstellung bearbeiten',
        PERMISSION_DELETE_SETTING: 'Einstellung löschen',
        PERMISSION_CREATE_PERMISSION: 'Berechtigungen erstellen',
        PERMISSION_UPDATE_PERMISSION: 'Berechtigungen aktualisieren',
        PERMISSION_GIVE_PERMISSION: 'Berechtigungen vergeben',
        PERMISSION_GIVE_ROLE: 'Rollen verwalten',
        PERMISSION_SUPER_USER: 'Superuser',
      },
    },
  },
  fallbackLng: 'de',
  debug: true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: '',
  },

  react: {
    wait: true,
  },
});

export default i18n;
