import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { LinearProgress } from '@material-ui/core';

/* CUSTOM */
import {
  createGroupMutation,
  deleteGroupMutation,
  getAllGroups,
  getGroups,
  updateGroupMutation,
} from '../../../graphql/group';
import ConfirmationDialog from '../../../components/helpers/ConfirmationDialog';
import GroupForm from '../components/GroupForm';
import { getAllInstitutions } from '../../../graphql/institution';
import AsyncTable from '../../../components/helpers/AsyncTable';

export function GroupList({ user, history, ...otherProps }) {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [openGroupDialog, setOpenGroupDialog] = useState(false);
  const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState(false);

  const [deleteGroup] = useMutation(deleteGroupMutation);
  const [updateGroup] = useMutation(updateGroupMutation);
  const [createGroup] = useMutation(createGroupMutation);

  /* TABLE LOGIC */

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingSearch, setIsFetchingSearch] = useState(false);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const searchRef = useRef(searchValue);
  searchRef.current = searchValue;

  let { data: institutionData } = useQuery(getAllInstitutions);
  let { refetch: refetchAllGroups } = useQuery(getAllGroups);

  let { loading: isLoading, data, refetch } = useQuery(getGroups, {
    variables: { query: '', page: 1 },
  });

  useEffect(() => {
    if (!isLoading && isFetchingSearch) setIsFetchingSearch(false);
  }, [isLoading]);

  const handleSearch = () => {
    setIsFetchingSearch(true);
    setPage(0);
    handleRefetchGroups({ query: searchRef.current, page: 1 });
  };

  const handlePageChange = (page) => {
    setPage(page);
    handleRefetchGroups({ query: searchRef.current, page: page + 1 });
  };

  const handleRefetchGroups = ({ query, page }) => {
    refetch({ query, page });
    refetchAllGroups();
  };

  const handleClose = () => {
    setSelectedGroup(null);
    setOpenGroupDialog(false);
    setOpenDeleteGroupDialog(false);
  };

  const handleEditGroupButton = (rowData) => {
    setSelectedGroup(rowData);
    setOpenGroupDialog(true);
  };

  const handleDeleteGroupButton = (rowData) => {
    setSelectedGroup(rowData);
    setOpenDeleteGroupDialog(true);
  };

  const handleGroupSubmit = (values) => {
    setIsFetching(true);
    const id = !!selectedGroup ? selectedGroup.id : null;
    handleClose();
    const variables = {
      name: values.name,
      institution_id: values.institution_id,
    };
    if (!id) {
      createGroup({ variables })
        .then((res) => {
          handleRefetchGroups({
            query: searchRef.current,
            page: page + 1,
          });
          setIsFetching(false);
        })
        .catch((e) => console.log(e));
    } else {
      variables.id = id;
      updateGroup({ variables })
        .then((res) => {
          handleRefetchGroups({ query: searchRef.current, page: page + 1 });
          setIsFetching(false);
        })
        .catch((e) => console.log(e));
    }
  };

  const handleDeleteGroup = () => {
    setIsFetching(true);
    const { id } = selectedGroup;
    handleClose();
    deleteGroup({ variables: { id } })
      .then(() => {
        handleRefetchGroups({ query: searchRef.current, page: page + 1 });
        setIsFetching(false);
      })
      .catch((e) => console.log(e));
  };

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Institution', field: 'institution.institution_name' },
  ];

  return (
    <div className={classNames({ 'is-loading': isLoading })} {...otherProps}>
      {!data ? (
        <LinearProgress />
      ) : (
        <>
          <AsyncTable
            handleAdd={() => setOpenGroupDialog(true)}
            handleEditItem={handleEditGroupButton}
            handleDeleteItem={handleDeleteGroupButton}
            title="Gruppen"
            columns={columns}
            data={data && data.groupSearch && data.groupSearch.data}
            total={data && data.groupSearch && data.groupSearch.totalCount}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            page={page}
            pageSize={100}
            isFetching={isFetchingSearch}
            handlePageChange={handlePageChange}
          />{' '}
          {openDeleteGroupDialog && (
            <ConfirmationDialog
              title="Soll die Gruppe unwiderruflich gelöscht werden?"
              discardText="Abbrechen"
              discardColor="default"
              discardAction={handleClose}
              confirmText="Ja, löschen"
              confirmColor="secondary"
              isFetching={isFetching}
              confirmAction={handleDeleteGroup}
            />
          )}
          {openGroupDialog && (
            <GroupForm
              selectedGroup={selectedGroup}
              handleClose={handleClose}
              handleSubmit={handleGroupSubmit}
              institutions={institutionData && institutionData.institutions}
              isFetching={isFetching}
            />
          )}
        </>
      )}
    </div>
  );
}

function withRedux({ user }) {
  return { user };
}

export default connect(withRedux, null)(withRouter(GroupList));
