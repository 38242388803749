import ApolloClient from 'apollo-boost';
import { store } from '../redux/store';
import { setGlobalErrors } from '../redux/actions/globalError';

export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_SERVER}/graphql`,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
  /*
    after a user is logged in and got an authorisation token back from the api
    graphql is using this very token for every request
    resource:
        - https://www.apollographql.com/docs/react/networking/authentication/
        - https://daveceddia.com/access-redux-store-outside-react/
    */
  request: (operation) => {
    const state = store.getState();
    const token = state.user.authenticationToken;
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        applicationName: `${process.env.REACT_APP_NAME}`,
      },
    });
  },
  onError: (response) => {
    store.dispatch(setGlobalErrors(response));
    response.forward(response.operation);
    response.networkError = null;
    response.graphQLErrors = [];
  },
});
