import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/css';
import { Formik, Form as FormikForm, FieldArray, Field } from 'formik';
import Plus from '../../components/icons/plus';
import Minus from '../../components/icons/minus';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { batchCreateUsersWithLicensesMutation } from '../../graphql/user';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles, List, ListItem, FormGroup } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { customHandleChange, findProductName } from '../../utils';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getProducts } from '../../graphql/product';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';

const Form = styled(FormikForm)`
  width: 100%;
  height: auto;

  box-sizing: border-box;
  padding: 10px 24px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  > svg {
    width: 150px;
    fill: ${colors.dark_grey};
  }

  > img {
    width: 150px;
    height: 150px;

    object-fit: fill;

    box-sizing: border-box;
    border-radius: 50%;
  }
`;

const Group = styled.section`
  width: 100%;

  box-sizing: border-box;
  padding: 20px 0;

  > h2 {
    margin-top: 0;

    box-sizing: border-box;
    padding: 0 20px;
    border-bottom: 1px solid ${colors.semi_dark_grey};

    font-size: 18px;
    text-align: left;
  }
`;

const Buttons = styled.section`
  width: 100%;

  box-sizing: border-box;
  padding: 50px 20px 0 20px;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Add = styled.div`
  justify-self: center;
  align-self: center;

  min-width: 40px;
  min-height: 40px;

  box-sizing: border-box;
  padding: 5px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${colors.blue};
`;

const CloseColored = styled.div`
  justify-self: center;
  align-self: center;
  cursor: pointer;

  min-width: 40px;
  min-height: 40px;

  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${colors.red};
`;

const defaultUser = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  is_owner: false,
  isUser: true,
};

function Component({ history, ...otherProps }) {
  const [batchCreateWithLicensesMutation] = useMutation(
    batchCreateUsersWithLicensesMutation,
  );
  let { loading: isProductLoading, data: productData } = useQuery(getProducts);
  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  if (isProductLoading) {
    return <div>Loading</div>;
  }

  function submit(values) {
    batchCreateWithLicensesMutation({
      variables: {
        ...values,
        users: values.users.map((user) => ({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          password: user.password,
          is_owner: user.is_owner ? user.is_owner : false,
        })),
      },
    });
    history.push('/user');
  }

  const validationSchema = {
    headline: t('registrationFailedHeadline'),
    description: t('registrationFailedDescription'),
    schema: Yup.object().shape({
      first_name: Yup.string()
        .required(
          t('fieldIsRequired', {
            name: t('firstName'),
          }),
        )
        .trim()
        .min(
          2,
          t('fieldIsTooShort', {
            name: t('firstName'),
            minimumCount: 2,
          }),
        ),
      last_name: Yup.string()
        .required(
          t('fieldIsRequired', {
            name: t('lastName'),
          }),
        )
        .trim()
        .min(
          2,
          t('fieldIsTooShort', {
            name: t('lastName'),
            minimumCount: 2,
          }),
        ),
      email: Yup.string()
        .email(
          t('fieldIsNotAnEmail', {
            name: t('email'),
          }),
        )
        .required(
          t('fieldIsRequired', {
            name: t('email'),
          }),
        ),
      password: Yup.string().required(
        t('fieldIsRequired', {
          name: t('password'),
        }),
      ),
    }),
  };

  const initialValues = {
    users: [
      {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        is_owner: true,
        isUser: false,
      },
    ],
    device_limit: 3,
    product_id:
      productData['products'].length === 1
        ? productData['products'][0]['id']
        : '',
    time_to_live: new Date(),
    date_of_activation: new Date(),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={submit}
      render={({ values, errors, handleChange }) => {
        const {
          users,
          device_limit,
          product_id,
          time_to_live,
          date_of_activation,
        } = values;
        return (
          <Form className={classes.root} {...otherProps}>
            <h2>Benutzer Hinzufügen</h2>
            <Divider />
            <div>
              <h3>Käufer</h3>
              <TextField
                required
                multiline
                name="users[0].first_name"
                label="Vorname"
                value={users[0].first_name}
                onChange={handleChange}
              />
              <TextField
                required
                multiline
                name="users[0].last_name"
                label="Nachname"
                value={users[0].last_name || ''}
                onChange={handleChange}
              />
              <TextField
                required
                multiline
                name="users[0].email"
                label="E-Mail"
                value={users[0].email || ''}
                onChange={handleChange}
              />
              <TextField
                required
                name="users[0].password"
                label="Passwort"
                type="password"
                value={users[0].password || ''}
                onChange={handleChange}
              />
            </div>

            <Group>
              <h2>Nutzer</h2>
              <FieldArray
                name="users"
                render={({ push, remove }) => {
                  return (
                    <List>
                      {values.users
                        .filter((u, index) =>
                          users[0].isUser ? true : index > 0,
                        )
                        .map((user, index) => {
                          const trueIndex = index + 1;
                          return (
                            <ListItem key={index}>
                              <FormGroup row>
                                <TextField
                                  required
                                  multiline
                                  name={`users.${trueIndex}.first_name`}
                                  label="Vorname"
                                  value={user.first_name}
                                  onChange={handleChange}
                                />
                                <TextField
                                  required
                                  multiline
                                  name={`users.${trueIndex}.last_name`}
                                  label="Nachname"
                                  value={user.last_name}
                                  onChange={handleChange}
                                />
                                <TextField
                                  required
                                  multiline
                                  name={`users.${trueIndex}.email`}
                                  label="E-Mail"
                                  value={user.email}
                                  onChange={handleChange}
                                />
                                <TextField
                                  required
                                  type="password"
                                  name={`users.${trueIndex}.password`}
                                  label="Passwort"
                                  value={user.password}
                                  onChange={handleChange}
                                />
                                <Buttons>
                                  {index > 0 && (
                                    <Add onClick={() => remove(index)}>
                                      <Minus width="20px" fill="#fff" />
                                    </Add>
                                  )}
                                </Buttons>
                              </FormGroup>
                            </ListItem>
                          );
                        })}

                      <Buttons>
                        <Add onClick={() => push(defaultUser)}>
                          <Plus width="20px" fill="#fff" />
                        </Add>
                      </Buttons>
                    </List>
                  );
                }}
              />
            </Group>
            <Group>
              <h2>Lizenzinformationen</h2>
              <FormGroup row>
                <Autocomplete
                  options={productData['products'] || []}
                  getOptionLabel={(option) => `${option.id}`}
                  renderOption={(option) => (
                    <Typography noWrap>{`${option.name}`}</Typography>
                  )}
                  style={{ width: 300 }}
                  defaultValue={productData['products'].find(
                    (option) => option.id === product_id,
                  )}
                  name={`product_id`}
                  renderInput={(params) => (
                    <Field
                      component={TextField}
                      {...params}
                      label={`Produkt: ${findProductName(
                        params.inputProps.value,
                        productData['products'],
                      )}`}
                      variant="outlined"
                      name={`product_id`}
                      fullWidth
                    />
                  )}
                  onSelect={(e) => {
                    customHandleChange(`product_id`, handleChange, e);
                  }}
                  renderTags={(option) => (
                    <Typography noWrap>{`${option.name}`}</Typography>
                  )}
                />
                <TextField
                  required
                  multiline
                  name="device_limit"
                  label="Gerätelimit"
                  value={device_limit}
                  onChange={handleChange}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    label="Aktivierungsdatum"
                    value={date_of_activation}
                    onChange={(value) =>
                      handleChange({
                        target: { value, name: `date_of_activation` },
                      })
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    name={`date_of_activation`}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    label="Aktivierungsende"
                    value={time_to_live}
                    onChange={(value) =>
                      handleChange({ target: { value, name: `time_to_live` } })
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    name={`time_to_live`}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>
            </Group>
            <div
              style={{
                width: '100%',
                display: 'flex',
                marginTop: '35px',
                justifyContent: 'space-between',
              }}>
              <CloseColored
                style={{ width: '48%', boxShadow: 'unset' }}
                onClick={() => history.push('/user')}>
                Abbrechen
              </CloseColored>
              <Button
                style={{ width: '50%', boxShadow: 'unset' }}
                type="submit"
                variant="contained"
                color="primary">
                Speichern
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default Component;
