import gql from 'graphql-tag';

const getProducts = gql`
  query products {
    products(limit: 100) {
      id
      name
      icon
      web_store_url
      play_store_url
      ios_store_url
    }
  }
`;

const getProduct = gql`
  query product($id: Int) {
    product(id: $id) {
      id
      name
      icon
      ios_store_url
      play_store_url
      web_store_url
    }
  }
`;

const updateProductMutation = gql`
  mutation updateProduct(
    $id: Int!
    $name: String!
    $icon: String
    $web_store_url: String
    $play_store_url: String
    $ios_store_url: String
  ) {
    updateProduct(
      id: $id
      name: $name
      icon: $icon
      web_store_url: $web_store_url
      play_store_url: $play_store_url
      ios_store_url: $ios_store_url
    ) {
      id
      name
      icon
      web_store_url
      play_store_url
      ios_store_url
    }
  }
`;

const createProductMutation = gql`
  mutation createProduct(
    $name: String!
    $icon: String
    $web_store_url: String
    $play_store_url: String
    $ios_store_url: String
  ) {
    createProduct(
      name: $name
      icon: $icon
      web_store_url: $web_store_url
      play_store_url: $play_store_url
      ios_store_url: $ios_store_url
    ) {
      id
      name
      icon
      web_store_url
      play_store_url
      ios_store_url
    }
  }
`;

const deleteProductMutation = gql`
  mutation deleteProduct($id: Int!) {
    deleteProduct(id: $id) {
      id
      name
      icon
      web_store_url
      play_store_url
      ios_store_url
    }
  }
`;

export {
  getProducts,
  getProduct,
  updateProductMutation,
  createProductMutation,
  deleteProductMutation,
};
