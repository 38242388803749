import gql from 'graphql-tag';

const addUserMutation = gql`
  mutation createUser(
    $first_name: String
    $last_name: String
    $email: String!
    $password: String!
  ) {
    createUser(
      first_name: $first_name
      last_name: $last_name
      email: $email
      password: $password
    ) {
      id
      email
      first_name
      last_name
      avatar
    }
  }
`;

const createUserWithLicencesMutation = gql`
  mutation createUserWithLicensesMutation(
    $user: SingleUserInput
    $device_limit: Int
    $product_ids: [Int]
    $date_of_activation: String
    $time_to_live: String
    $generatePassword: Boolean
  ) {
    createUserWithLicensesMutation(
      user: $user
      device_limit: $device_limit
      product_ids: $product_ids
      date_of_activation: $date_of_activation
      time_to_live: $time_to_live
      generatePassword: $generatePassword
    ) {
      id
      email
      first_name
      last_name
      group {
        id
        name
      }
      avatar
      institution {
        institution_name
      }
    }
  }
`;

const getUser = (id) => gql`
    {
      user(id: ${id}) {
        id
        email
        first_name
        last_name
        avatar
        address {
          id
          street
          number
          postal_code
          country
        }
      }
    }
`;

const getEmail = gql`
  query getUser($id: Int!) {
    user(id: $id) {
      email
    }
  }
`;

const getUserOnly = gql`
  query getUser($id: Int!) {
    user(id: $id) {
      id
      email
      first_name
      last_name
      avatar
      licenses {
        id
        device_limit
        date_of_activation
        time_to_live
        customer {
          id
          email
          first_name
          last_name
          avatar
          address {
            id
            street
            number
            postal_code
            country
          }
        }
        user {
          id
          email
          first_name
          last_name
          avatar
          address {
            id
            street
            number
            postal_code
            country
          }
        }
        product {
          id
          name
          ios_store_url
          play_store_url
          web_store_url
          icon
        }
      }
      permissions {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;

const updateUserMutation = gql`
  mutation updateUser(
    $id: Int!
    $first_name: String
    $last_name: String
    $email: String
    $password: String
    $permissions: [PermissionInput]
    $roles: [RoleInput]
    $group_id: Int
    $institution_id: Int
  ) {
    updateUser(
      id: $id
      first_name: $first_name
      last_name: $last_name
      email: $email
      password: $password
      permissions: $permissions
      roles: $roles
      group_id: $group_id
      institution_id: $institution_id
    ) {
      id
      email
      first_name
      last_name
      avatar
      group {
        id
        name
      }
      permissions {
        id
        name
      }
      institution {
        id
        institution_name
      }
      roles {
        id
        name
        permissions {
          id
          name
        }
      }
    }
  }
`;

const deleteUserMutation = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const forceDeleteUserMutation = gql`
  mutation forceDeleteUser($id: Int!) {
    forceDeleteUser(id: $id) {
      id
    }
  }
`;

const updateUsersInviteWithPasswordMutation = gql`
  mutation updateUsersInviteWithPassword(
    $id: Int!
    $generatePassword: Boolean
  ) {
    updateUsersInviteWithPassword(
      id: $id
      generatePassword: $generatePassword
    ) {
      id
    }
  }
`;

const getAllUsersBySearchString = gql`
  query getUser($query: String, $institutionQuery: Int, $page: Int) {
    userSearch(query: $query, institutionQuery: $institutionQuery  page: $page) {
      totalCount
      page
      data {
        id
        email
        first_name
        last_name
        deleted_at
        email_sent
        group {
          id
          name
        }
        institution {
          id
          institution_name
        }
        licenses {
          id
          customer {
            id
            email
            first_name
            last_name
          }
          product {
            id
            name
          }
          device_limit
          date_of_activation
          time_to_live
        }
      }
    }
  }
`;

const batchCreateUsersWithLicensesMutation = gql`
  mutation batchCreateWithLicensesMutation(
    $users: [UserInput]
    $device_limit: Int
    $product_id: Int
    $date_of_activation: String
    $time_to_live: String
  ) {
    batchCreateWithLicensesMutation(
      users: $users
      device_limit: $device_limit
      product_id: $product_id
      date_of_activation: $date_of_activation
      time_to_live: $time_to_live
    ) {
      id
      email
      first_name
      last_name
      avatar
    }
  }
`;

export {
  addUserMutation,
  getUser,
  getUserOnly,
  updateUserMutation,
  deleteUserMutation,
  getAllUsersBySearchString,
  batchCreateUsersWithLicensesMutation,
  createUserWithLicencesMutation,
  getEmail,
  forceDeleteUserMutation,
  updateUsersInviteWithPasswordMutation
};
