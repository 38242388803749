import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from '../../../styled/form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  createRoleMutation,
  deleteRoleMutation,
  updateRoleMutation,
} from '../../../graphql/role';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Permission } from '../../../containers/user/permission';
import { getPermissionsQuery } from '../../../graphql/permission';

export default function Role({ match, history, role, ...otherProps }) {
  const variables = { id: role && role.id ? parseInt(role.id, 10) : null };
  const isEdit = variables.id || false;
  let [roleData, setRoleData] = useState(
    role || {
      name: '',
    },
  );

  const title = role ? '' : 'Rolle anlegen';

  const { data: getPermissionData } = useQuery(getPermissionsQuery);
  const [updateRole, { data: updateRoleData }] = useMutation(
    updateRoleMutation,
  );
  const [createRole, { data: createRoleData }] = useMutation(
    createRoleMutation,
  );
  const [deleteRole] = useMutation(deleteRoleMutation);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root, .MuiButton-root': {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  const { name } = roleData;

  function submit(values, { setSubmitting }) {
    if (isEdit) {
      updateRole({
        variables: {
          ...variables,
          ...values,
          permissions: values.permissions
            .map((permission) => permission.active && { name: permission.name })
            .filter((perm) => perm),
        },
      });
    } else {
      createRole({
        variables: {
          ...variables,
          ...values,
          permissions: values.permissions
            .map((permission) => permission.active && { name: permission.name })
            .filter((perm) => perm),
        },
      });
    }
    history.push('/roles');
    setSubmitting(false);
  }

  function handleDeleteRole(id) {
    deleteRole({ variables: { id } }).then(() => {
      history.push('/roles');
    });
  }

  useEffect(() => {
    if (updateRoleData) {
      setRoleData(updateRoleData['updateRole']);
    } else if (createRoleData) {
      setRoleData(createRoleData['createRole']);
    }
  }, [updateRoleData, createRoleData]);

  const permissions = getPermissionData
    ? getPermissionData.permissions.map((perm) => {
        if (
          roleData &&
          roleData.permissions &&
          roleData.permissions.find((rolePermission) => {
            if (rolePermission && perm) {
              return rolePermission.name === perm.name;
            }
            return false;
          })
        ) {
          return { ...perm, active: true };
        } else {
          return perm;
        }
      })
    : [];

  return (
    <Formik
      initialValues={{ name, permissions }}
      enableReinitialize
      onSubmit={submit}
      render={({ values, handleChange, handleSubmit }) => {
        const { name, permissions } = values;
        return (
          <Form className={classes.root}>
            {title && <h2>{title}</h2>}
            <div>
              <TextField
                required
                multiline
                name="name"
                label="Name"
                value={name || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              {permissions.length && (
                <Permission
                  permissions={permissions}
                  handleChange={handleChange}
                />
              )}
            </div>
            <div>
              {isEdit && (
                <Button
                  onClick={() => handleDeleteRole(isEdit)}
                  variant="contained"
                  color="secondary">
                  Löschen
                </Button>
              )}
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary">
                Speichern
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}
