import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';

/*MATERIAL UI */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import { COUNTRIES_DE } from '../../../data/utils/countries';

const Form = styled(FormikForm)`
  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
  }

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -22px;
  }

  h3 {
    margin: 10px 0 20px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
  }

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -22px;
  }
  .MuiInput-underline.Mui-error:after {
    transform: scaleX(1);
    border-bottom-color: #f44336;
  }
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

function InstitutionForm({
  handleClose,
  selectedInstitution,
  handleSubmit,
  isFetching,
}) {
  const validateForm = (values) => {
    const errors = {};
    if (!values.institution_name) {
      errors.institution_name = 'Feld wird benötigt';
    }
    if (!values.address_country) {
      errors.address_country = 'Feld wird benötigt';
    }
    if (!values.address_street) {
      errors.address_street = 'Feld wird benötigt';
    }
    if (!values.address_number) {
      errors.address_number = 'Feld wird benötigt';
    }
    if (!values.address_postal_code) {
      errors.address_postal_code = 'Feld wird benötigt';
    }
    if (!values.address_city) {
      errors.address_city = 'Feld wird benötigt';
    }
    if (
      !!values.contact_email &&
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        values.contact_email,
      )
    ) {
      errors.contact_email = 'Ungültige E-Mail Adresse';
    }
    return errors;
  };

  const initialInstitution = {
    institution_name: !!selectedInstitution
      ? selectedInstitution.institution_name
      : '',
    first_name:
      !!selectedInstitution && !!selectedInstitution.first_name
        ? selectedInstitution.first_name
        : '',
    last_name:
      !!selectedInstitution && !!selectedInstitution.last_name
        ? selectedInstitution.last_name
        : '',
    vat_id:
      !!selectedInstitution && !!selectedInstitution.vat_id
        ? selectedInstitution.vat_id
        : '',
    tax_id:
      !!selectedInstitution && !!selectedInstitution.tax_id
        ? selectedInstitution.tax_id
        : '',
    address_street:
      !!selectedInstitution && !!selectedInstitution.address.street
        ? selectedInstitution.address.street
        : '',
    address_number:
      !!selectedInstitution && !!selectedInstitution.address.number
        ? selectedInstitution.address.number
        : '',
    address_postal_code:
      !!selectedInstitution && !!selectedInstitution.address.postal_code
        ? selectedInstitution.address.postal_code
        : '',
    address_country:
      !!selectedInstitution && !!selectedInstitution.address.country
        ? selectedInstitution.address.country
        : '',
    address_city:
      !!selectedInstitution && !!selectedInstitution.address.city
        ? selectedInstitution.address.city
        : '',
    address_additional:
      !!selectedInstitution && !!selectedInstitution.address.additional
        ? selectedInstitution.address.additional
        : '',
    contact_email:
      !!selectedInstitution && !!selectedInstitution.contact_email
        ? selectedInstitution.contact_email
        : '',
    // leader_id: !!selectedInstitution ? selectedInstitution.leader_id : null,
  };
  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ paddingTop: '15px' }}>
        Institution {!!selectedInstitution ? 'bearbeiten' : 'hinzufügen'}
      </DialogTitle>
      <Formik
        initialValues={initialInstitution}
        validate={validateForm}
        validateOnBlur={true}
        onSubmit={handleSubmit}
        render={({ values, errors, handleBlur, touched, handleChange }) => {
          const {
            institution_name,
            first_name,
            last_name,
            vat_id,
            tax_id,
            address_street,
            address_number,
            address_country,
            address_postal_code,
            address_city,
            address_additional,
            contact_email,
            // leader_id TBD,
          } = values;

          return (
            <Form>
              <DialogContent style={{ paddingTop: '10px' }}>
                <h3>Institution</h3>
                <TextField
                  error={
                    !!touched.institution_name && !!errors.institution_name
                  }
                  helperText={
                    !!touched.institution_name &&
                    !!errors.institution_name &&
                    errors.institution_name
                  }
                  name="institution_name"
                  label="Name *"
                  value={institution_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FieldWrapper>
                  <TextField
                    error={!!touched.address_street && !!errors.address_street}
                    helperText={
                      !!touched.address_street &&
                      !!errors.address_street &&
                      errors.address_street
                    }
                    style={{ width: 'calc(70% - 10px)' }}
                    name="address_street"
                    label="Straße *"
                    value={address_street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    error={!!touched.address_number && !!errors.address_number}
                    helperText={
                      !!touched.address_number &&
                      !!errors.address_number &&
                      errors.address_number
                    }
                    style={{ width: 'calc(30% - 10px)' }}
                    name="address_number"
                    label="Hausnummer *"
                    value={address_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    error={
                      !!touched.address_postal_code &&
                      !!errors.address_postal_code
                    }
                    helperText={
                      !!touched.address_postal_code &&
                      !!errors.address_postal_code &&
                      errors.address_postal_code
                    }
                    style={{ width: 'calc(30% - 10px)' }}
                    name="address_postal_code"
                    label="Postleitzahl *"
                    value={address_postal_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    error={!!touched.address_city && !!errors.address_city}
                    helperText={
                      !!touched.address_city &&
                      !!errors.address_city &&
                      errors.address_city
                    }
                    style={{ width: 'calc(70% - 10px)' }}
                    name="address_city"
                    label="Ort *"
                    value={address_city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    style={{ width: 'calc(50% - 10px)' }}
                    name="address_additional"
                    label="Adresszusatz"
                    value={address_additional}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormControl
                    style={{ width: 'calc(50% - 10px)' }}
                    error={!!touched.address_country && errors.address_country}>
                    <InputLabel id="country-select-label">Land *</InputLabel>
                    <Select
                      onBlur={handleBlur}
                      labelId="country-select-label"
                      value={address_country}
                      name="address_country"
                      onChange={handleChange}>
                      <MenuItem value="">Auswahl entfernen</MenuItem>
                      {COUNTRIES_DE.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.address_country && !!touched.address_country && (
                      <FormHelperText>{errors.address_country}</FormHelperText>
                    )}
                  </FormControl>
                </FieldWrapper>
              </DialogContent>{' '}
              <DialogContent style={{ paddingTop: '10px' }}>
                <h3>Steuerdaten</h3>
                <FieldWrapper>
                  <TextField
                    style={{ width: 'calc(50% - 10px)' }}
                    name="tax_id"
                    label="Steuernummer"
                    value={tax_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <TextField
                    style={{ width: 'calc(50% - 10px)' }}
                    name="vat_id"
                    label="USt-Identifikationsnummer"
                    value={vat_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FieldWrapper>
              </DialogContent>
              <DialogContent style={{ paddingTop: '10px' }}>
                <h3>Ansprechpartner</h3>
                <FieldWrapper>
                  <TextField
                    style={{ width: 'calc(50% - 10px)' }}
                    name="first_name"
                    label="Vorname"
                    value={first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    style={{ width: 'calc(50% - 10px)' }}
                    name="last_name"
                    label="Nachname"
                    value={last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FieldWrapper>
                <TextField
                  style={{}}
                  name="contact_email"
                  label="E-Mail Adresse"
                  value={contact_email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!touched.contact_email && !!errors.contact_email}
                  helperText={
                    !!touched.contact_email &&
                    !!errors.contact_email &&
                    errors.contact_email
                  }
                />
              </DialogContent>
              <DialogActions style={{ padding: '25px 20px' }}>
                {isFetching ? (
                  <CircularProgress style={{ width: '20px', height: '20px' }} />
                ) : (
                  <>
                    <Button color="default" onClick={handleClose}>
                      Abbrechen
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disableElevation>
                      Speichern
                    </Button>
                  </>
                )}
              </DialogActions>
            </Form>
          );
        }}></Formik>
    </Dialog>
  );
}
InstitutionForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedInstitution: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.func.isRequired,
};

export default InstitutionForm;
