import { combineReducers } from 'redux';
import user from './user';
import lastLocation from './lastLocation';
import appElement from './appElement';
import globalError from './globalError';

export default combineReducers({
  user,
  lastLocation,
  appElement,
  globalError,
});
