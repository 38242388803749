import gql from 'graphql-tag';

const getRoles = gql`
  query roles {
    roles(limit: 100) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

const getRole = gql`
  query role($id: Int) {
    role(id: $id) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

const updateRoleMutation = gql`
  mutation updateRole(
    $id: Int!
    $name: String!
    $permissions: [PermissionInput]
  ) {
    updateRole(id: $id, name: $name, permissions: $permissions) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

const createRoleMutation = gql`
  mutation createRole($name: String!, $permissions: [PermissionInput]) {
    createRole(name: $name, permissions: $permissions) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

const deleteRoleMutation = gql`
  mutation deleteRole($id: Int!) {
    deleteRole(id: $id) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

export {
  getRoles,
  getRole,
  updateRoleMutation,
  createRoleMutation,
  deleteRoleMutation,
};
