// react
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// styles
import styled from 'styled-components';
import { colors } from '../../variables/css';
// hoc
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
// components
import Loading from '../loading';
import Input from '../../components/dataEntry/input/default';
import { Primary as Button, Default } from '../../components/buttons';
import Error from '../../containers/error';
// data
import { useCookies } from 'react-cookie';
import { signIn } from '../../redux/actions';
import { useRegister } from './useRegister';
// helper
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Form)`
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > * {
    margin: 20px 0;
  }
`;

const Heading = styled.h1`
  white-space: nowrap;
  color: ${colors.dark_blue};
  font-size: 30px;
`;

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  c_password: '',
};

/**  */
export function Register({ signIn, history, ...otherProps }) {
  const [values, setValues] = useState(null);
  const [loading, error, user, token] = useRegister(values);
  const [cookies, setCookie] = useCookies(['auth-token']);
  const { t } = useTranslation();

  useEffect(() => {
    if (!!user && !!token) {
      setCookie('auth-token', token, { maxAge: 864000 });
      // set data in redux
      signIn({ user, token });
      // redirect
      history.push('/');
    }
  }, [user]);

  function submit(values, { setSubmitting }) {
    setValues(values);
    setSubmitting(false);
  }

  function reset() {
    setValues(null);
  }

  if (loading) return <Loading />;

  if (error)
    return (
      <Error
        title="Registrierung Fehlgeschlagen"
        description="Bei der Registrierung ist etwas schief gelaufen. Bitte prüfe deine Angaben erneut und versuche es noch einmal."
        onAccept={reset}
      />
    );

  const validationSchema = {
    headline: t('registrationFailedHeadline'),
    description: t('registrationFailedDescription'),
    schema: Yup.object().shape({
      first_name: Yup.string()
        .required(
          t('fieldIsRequired', {
            name: t('firstName'),
          }),
        )
        .trim()
        .min(
          2,
          t('fieldIsTooShort', {
            name: t('firstName'),
            minimumCount: 2,
          }),
        ),
      last_name: Yup.string()
        .required(
          t('fieldIsRequired', {
            name: t('lastName'),
          }),
        )
        .trim()
        .min(
          2,
          t('fieldIsTooShort', {
            name: t('lastName'),
            minimumCount: 2,
          }),
        ),
      email: Yup.string()
        .email(
          t('fieldIsNotAnEmail', {
            name: t('email'),
          }),
        )
        .required(
          t('fieldIsRequired', {
            name: t('email'),
          }),
        ),
      password: Yup.string().required(
        t('fieldIsRequired', {
          name: t('password'),
        }),
      ),
      c_password: Yup.string().test(
        'passwords-match',
        t('fieldsAreNotMatching', {
          name1: t('password'),
          name2: t('cPassword'),
        }),
        function (value) {
          return this.parent.password === value;
        },
      ),
    }),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={submit}
      render={({ values, errors, handleChange, handleSubmit }) => {
        return (
          <Wrapper {...otherProps}>
            <Heading>Registrierung</Heading>
            <Input
              name="first_name"
              placeholder="Vorname"
              value={values['first_name']}
              onChange={handleChange}
            />
            <Input
              name="last_name"
              placeholder="Nachname"
              value={values['last_name']}
              onChange={handleChange}
            />
            <Input
              name="email"
              placeholder="E-Mail"
              value={values['email']}
              onChange={handleChange}
            />
            <Input
              name="password"
              type="password"
              placeholder="Passwort"
              value={values['password']}
              onChange={handleChange}
            />
            <Input
              name="c_password"
              type="password"
              placeholder="Passwort Kontrolle"
              value={values['c_password']}
              onChange={handleChange}
            />
            <Button type="submit" onClick={handleSubmit}>
              Registrieren
            </Button>
            <Default type="submit" onClick={history.goBack}>
              Zurück
            </Default>
          </Wrapper>
        );
      }}
    />
  );
}

Register.propTypes = {
  signIn: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

Register.defaultProps = {};

export default connect(null, { signIn })(withRouter(Register));
