import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { routes, sidepanelRoutes } from './data';
import Menu from './menu';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { theme } from '../../variables/css';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerRight: {
    width: `${2 * drawerWidth - theme.spacing(6)}px`,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100% - (40px + ${drawerWidth}px))`,
  },
  toolbar: theme.mixins.toolbar,
}));

const Wrapper = styled.div`
  display: flex;
`;

export default function Dashboard({ history }) {
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Menu history={history} useStyles={useStyles} />
        <main className={classes.content}>
          <Switch>
            {routes.map(({ title, link, component, ...otherProps }, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={link}
                  component={component}
                  {...otherProps}
                />
              );
            })}
            <Redirect from="/*" to={routes[0].link} />
          </Switch>
          <Drawer
            className={classes.drawerRight}
            anchor="right"
            variant="persistent"
            open={sidepanelRoutes.find(
              (item) => item.link === history.location.pathname,
            )}
            onClose={toggleDrawer('right', false)}
            classes={{
              paper: classes.drawerRight,
            }}>
            <div className={classes.toolbar}>
              <Switch>
                {sidepanelRoutes.map(
                  ({ title, link, component, ...otherProps }, index) => {
                    return (
                      <Route
                        key={index}
                        exact
                        path={link}
                        component={component}
                        {...otherProps}
                      />
                    );
                  },
                )}
              </Switch>
            </div>
          </Drawer>
        </main>
      </Wrapper>
    </ThemeProvider>
  );
}
