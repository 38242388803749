import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { createUserWithLicencesMutation } from '../../graphql/user';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Formik, Form as FormikForm } from 'formik';
import '../../css/list.css';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Checkbox, CircularProgress } from '@material-ui/core';

const Form = styled(FormikForm)`
  .formik-field-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiTextField-root.MuiFormControl-root {
      width: calc(50% - 10px);
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
  }

  .MuiAutocomplete-root {
    .MuiFormControl-root {
      margin: 0;
    }
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
  }
  h3 {
    margin: 10px 0 20px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
  }
`;

const CheckboxWrapper = styled.div`
  width: calc(50% - 10px);
  transform: translateX(-10px);
  display: flex;
  transition: 0.3s;
  align-items: center;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }

  p {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
`;

function UserForm({
  handleClose,
  userData,
  productData,
  groupsData,
  institutions,
}) {
  const [isFetching, setIsFetching] = useState(false);
  const [createWithLicensesMutation] = useMutation(
    createUserWithLicencesMutation,
  );

  const [showProductSelection, setShowProductSelection] = useState(false);

  const validateForm = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Feld wird benötigt';
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        values.email,
      )
    ) {
      errors.email = 'Ungültige E-Mail Adresse';
    }
    if (values.first_name.trim().length < 1) {
      errors.first_name = 'Feld wird benötigt';
    }
    if (values.last_name.trim().length < 1) {
      errors.last_name = 'Feld wird benötigt';
    }
    if (
      values.time_to_live < values.date_of_activation ||
      isNaN(values.time_to_live.getTime())
    ) {
      errors.time_to_live = true;
    }
    if (isNaN(values.date_of_activation.getTime())) {
      errors.date_of_activation = true;
    }
    if (!values.generatePassword) {
      if (values.password.trim().length < 1) {
        errors.password = 'Feld wird benötigt.';
      } else if (values.password.trim().length < 8) {
        errors.password = 'Mindestens 8 Zeichen.';
      }
    }
    if (values.type === 'user' && !values.owner_id) {
      errors.owner_id = 'Feld wird benötigt.';
    } else if (values.type === 'user' && !!values.owner_id) {
      const foundOwner = userData.find(
        (owner) => values.owner_id === owner.email,
      ).id;
      if (!foundOwner) errors.owner_id = 'Nutzer nicht gefunden.';
    }
    return errors;
  };

  const submitForm = (values) => {
    setIsFetching(true);
    const {
      last_name,
      first_name,
      email,
      type,
      password,
      generatePassword,
      products,
      date_of_activation,
      time_to_live,
      device_limit,
      group_id: group,
      institution_id: institution,
    } = values;

    let owner_id = null;
    if (type === 'user')
      owner_id = userData.find((owner) => values.owner_id === owner.email).id;

    let institution_id = null;
    let group_id = group;
    if (institution !== '') {
      institution_id = institutions.find(
        (ins) => ins.institution_name === institution,
      ).id;
    } else {
      group_id = null;
    }

    const variables = {
      user: {
        first_name,
        last_name,
        password,
        owner_id,
        email,
        group_id: group_id !== '' ? group_id : null,
        institution_id,
      },
      product_ids: products,
      generatePassword,
      device_limit,
      date_of_activation,
      time_to_live,
    };

    createWithLicensesMutation({
      variables,
    })
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        setIsFetching(false);
      });
  };

  const initialUser = {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    generatePassword: false,
    device_limit: 3,
    owner_id: null,
    time_to_live: new Date(
      new Date().setFullYear(new Date().getFullYear() + 1),
    ),
    date_of_activation: new Date(),
    type: 'owner',
    products: [],
    group_id: '',
    institution_id: '',
  };
  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ paddingTop: '15px' }}>
        Benutzer hinzufügen
      </DialogTitle>
      <Formik
        initialValues={initialUser}
        validate={validateForm}
        validateOnBlur={true}
        onSubmit={submitForm}
        render={({ values, errors, handleBlur, touched, handleChange }) => {
          const {
            last_name,
            first_name,
            password,
            generatePassword,
            owner_id,
            device_limit,
            email,
            group_id,
            products,
            type,
            time_to_live,
            date_of_activation,
            institution_id,
          } = values;

          const displayedGroups = groupsData.filter((group) =>
            !!group.institution
              ? group.institution.institution_name === institution_id
              : false,
          );

          return (
            <Form>
              <DialogContent style={{ paddingTop: '10px' }}>
                <h3>Benutzerdaten</h3>
                <FormControl>
                  <Autocomplete
                    id="institution_id"
                    options={institutions}
                    name="institution_id"
                    onBlur={handleBlur}
                    getOptionLabel={(option) => `${option.institution_name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          !!touched.institution_id && !!errors.institution_id
                        }
                        helperText={
                          !!touched.institution_id &&
                          !!errors.institution_id &&
                          errors.institution_id
                        }
                        label="Institution"
                      />
                    )}
                    defaultValue={
                      !!institution_id
                        ? institutions.find(
                            (ins) => ins.institution_name === institution_id,
                          )
                        : null
                    }
                    onInputChange={(event, newInputValue) => {
                      handleChange({
                        ...newInputValue,
                        target: {
                          name: 'institution_id',
                          value: newInputValue,
                        },
                      });
                      if (!!newInputValue) {
                        handleChange({
                          ...newInputValue,
                          target: {
                            name: 'group_id',
                            value: '',
                          },
                        });
                      }
                    }}
                  />
                </FormControl>
                {institution_id && (
                  <FormControl>
                    <InputLabel id="group-select-label">
                      {!!displayedGroups.length
                        ? 'Gruppe'
                        : 'Keine Gruppe gefunden'}
                    </InputLabel>
                    <Select
                      onBlur={handleBlur}
                      labelId="group-select-label"
                      value={group_id}
                      name="group_id"
                      disabled={!displayedGroups.length > 0}
                      onChange={handleChange}>
                      {!!displayedGroups.length && (
                        <MenuItem value="">Auswahl entfernen</MenuItem>
                      )}

                      {displayedGroups.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <FormControl>
                  <InputLabel id="type-select-label">
                    Art des Benutzers
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="type-select-label"
                    value={type}
                    name="type"
                    onChange={handleChange}>
                    <MenuItem value="owner">Käufer</MenuItem>
                    <MenuItem value="user">Endnutzer</MenuItem>
                  </Select>
                </FormControl>
                {type === 'user' && (
                  <FormControl style={{ marginBottom: '20px' }}>
                    <Autocomplete
                      id="owner_id"
                      options={userData || []}
                      name="owner_id"
                      onBlur={handleBlur}
                      getOptionLabel={(option) => `${option.email}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!touched.owner_id && !!errors.owner_id}
                          helperText={
                            !!touched.owner_id &&
                            !!errors.owner_id &&
                            errors.owner_id
                          }
                          label="Käufer *"
                          margin="normal"
                        />
                      )}
                      value={userData.find((owner) => owner.id === owner_id)}
                      onSelect={(e) => {
                        handleChange({
                          ...e,
                          target: { name: 'owner_id', value: e.target.value },
                        });
                      }}
                    />
                  </FormControl>
                )}
                <div className="formik-field-wrapper">
                  <TextField
                    error={!!touched.first_name && !!errors.first_name}
                    helperText={
                      !!touched.first_name &&
                      !!errors.first_name &&
                      errors.first_name
                    }
                    name="first_name"
                    label="Vorname *"
                    value={first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    error={!!touched.last_name && !!errors.last_name}
                    helperText={
                      !!touched.last_name &&
                      !!errors.last_name &&
                      errors.last_name
                    }
                    name="last_name"
                    label="Nachname *"
                    value={last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>{' '}
                <TextField
                  error={!!touched.email && !!errors.email}
                  helperText={!!touched.email && !!errors.email && errors.email}
                  name="email"
                  label="E-Mail *"
                  type="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div
                  className={
                    generatePassword
                      ? 'formik-field-wrapper no-password-required'
                      : 'formik-field-wrapper'
                  }>
                  <TextField
                    error={!!touched.password && !!errors.password}
                    helperText={
                      generatePassword
                        ? ''
                        : !!touched.password && !!errors.password
                        ? errors.password
                        : 'Mindestens 8 Zeichen.'
                    }
                    name="password"
                    label={generatePassword ? 'Passwort' : 'Passwort *'}
                    type="password"
                    disabled={!!generatePassword}
                    value={password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <CheckboxWrapper className={generatePassword ? 'active' : ''}>
                    <Checkbox
                      color="default"
                      name="generatePassword"
                      checked={generatePassword}
                      onChange={(e) => {
                        handleChange({
                          target: { value: '', name: `password` },
                        });
                        handleChange(e);
                      }}
                    />
                    <p>Passwort erstellen und E-Mail senden</p>
                  </CheckboxWrapper>
                </div>
              </DialogContent>
              <DialogContent style={{ paddingTop: 0 }}>
                <h3>Lizenzinformationen</h3>
                <div className="formik-field-wrapper products-select">
                  <FormControl style={{ width: 'calc(70% - 10px)' }}>
                    <InputLabel id="products-label">Produkt/e</InputLabel>
                    <Select
                      labelId="products-label"
                      name="products"
                      multiple
                      onOpen={() => setShowProductSelection(true)}
                      onClose={() => setShowProductSelection(false)}
                      open={!!showProductSelection}
                      value={products}
                      onChange={handleChange}>
                      <MenuItem value="dummy-button">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setShowProductSelection(false);
                          }}>
                          Übernehmen
                        </Button>
                      </MenuItem>

                      {productData.products.map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    style={{ width: 'calc(30% - 10px)' }}
                    required
                    name="device_limit"
                    label="Gerätelimit"
                    value={device_limit}
                    type="number"
                    onChange={handleChange}
                  />
                </div>
                <div className="formik-field-wrapper">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      label="Aktivierungsdatum"
                      autoOk
                      value={date_of_activation}
                      onChange={(value) =>
                        handleChange({
                          target: { value, name: `date_of_activation` },
                        })
                      }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      name={`date_of_activation`}
                      invalidDateMessage="Ungültiges Datum."
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      autoOk
                      minDate={new Date().setDate(
                        date_of_activation.getDate() + 1,
                      )}
                      label="Aktivierungsende"
                      value={time_to_live}
                      onChange={(value) =>
                        handleChange({
                          target: { value, name: `time_to_live` },
                        })
                      }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      name={`time_to_live`}
                      invalidDateMessage="Ungültiges Datum."
                      minDateMessage="Datum muss nach Aktivierung liegen."
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </DialogContent>
              <DialogActions style={{ padding: '25px 20px' }}>
                {isFetching ? (
                  <CircularProgress style={{ width: '20px', height: '20px' }} />
                ) : (
                  <>
                    <Button color="default" onClick={handleClose}>
                      Abbrechen
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disableElevation>
                      Speichern
                    </Button>
                  </>
                )}
              </DialogActions>
            </Form>
          );
        }}></Formik>
    </Dialog>
  );
}
UserForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  userData: PropTypes.array.isRequired,
  productData: PropTypes.object.isRequired,
  groupsData: PropTypes.array.isRequired,
  institutions: PropTypes.array.isRequired,
};

export default UserForm;
