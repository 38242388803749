import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import DialogActions from '@material-ui/core/DialogActions';
import { CircularProgress } from '@material-ui/core';


const ResendEmailDialog = ({
  value,
  setValue,
  selectedUser,
  discardAction,
  confirmAction,
  isFetching,
}) => {
  return (
    <Dialog
      onClose={discardAction}
      className="licence-form"
      open
      aria-labelledby="form-dialog-title">
      <DialogContent>
        <p style={{ lineHeight: 1.5 }}>
          Bestätigungs-Email an {selectedUser.email} erneut senden?
        </p>
      </DialogContent>
      <DialogActions>
        {isFetching ? (
          <CircularProgress style={{ width: '20px', height: '20px' }} />
        ) : (
          <>
            <Button onClick={discardAction} color="default">
              Abbrechen
            </Button>
            <Button
              onClick={confirmAction}
              color="primary"
              variant="contained"
              disableElevation>
              Senden
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

ResendEmailDialog.propTypes = {
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  selectedUser: PropTypes.object.isRequired,
  discardAction: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default ResendEmailDialog;
