import styled, { keyframes } from 'styled-components';
import { colors } from '../../variables/css';
import { darken } from 'polished';

const List = styled.div``;

const swipeIn = keyframes`
  0% {
      right: -100%;
  }
  100% {
      right: 20px
  }
`;

const AddButton = styled.div`
  position: absolute;

  width: 295px;
  align-items: center;
  box-sizing: border-box;
  top: 30px;
  z-index: 99;
  right: 360px;

  display: flex;
  justify-content: center;
  align-items: center;

  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  color: white;

  background: ${colors.blue};

  > svg {
    width: 25px;
    fill: #fff;
  }

  :hover {
    cursor: pointer;
  }
`;

const Item = styled.li`
  width: 100%;
  height: 50px;

  display: grid;
  grid-template-columns: 50px 1fr 30px;
  grid-gap: 5px;

  box-sizing: border-box;
  padding: 0 10px;

  background: rgb(237, 237, 237);

  &.is-even {
    background: inherit;
  }
  &.is-deleted {
    background: ${colors.red};
    color: #fff;
    svg {
      &.arrow,
      &.avatar {
        fill: #fff;
      }
    }
  }

  :active,
  :focus,
  :hover {
    cursor: pointer;

    background: ${darken(0.1, colors.grey)};
  }

  > img {
    justify-self: center;
    align-self: center;

    width: 40px;
    height: 40px;

    box-sizing: border-box;
    border-radius: 50%;
  }

  > svg {
    justify-self: center;
    align-self: center;

    &.avatar {
      width: 40px;
      fill: ${colors.dark_grey};
    }

    &.arrow {
      width: 30px;
      fill: ${colors.dark_grey};

      transform: rotate(180deg);
    }
  }
`;

const ItemName = styled.p`
  margin: 0;
  padding: 0;

  justify-self: flex-start;
  align-self: center;
`;

export { List, swipeIn, AddButton, Item, ItemName };
