import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { clearGlobalErrors } from '../redux/actions/globalError';
import { signOut } from '../redux/actions';
import Error from '@appucations/core/dist/components/molecules/modal/error';
import Span from '@appucations/core/dist/components/atoms/span';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

export function Page({
  appElement,
  children,
  errors,
  clearGlobalErrors,
  signOut,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    const isUnauthorized = errors.some((error) => error.statusCode === 401);
    if (isUnauthorized) {
      // @TODO: shouldn't be needed, since signOut should reset the entire redux store
      clearGlobalErrors();
      signOut();
    }
  }, [errors]);

  return (
    <div className="page">
      {children}

      {errors && errors.length > 0 && (
        <Error
          appElement={appElement}
          title={t('generalErrorTitle')}
          description={t('generalErrorDescription')}
          modalIsOpen={true}
          setModalIsOpen={clearGlobalErrors}>
          {errors.map((error, index) => (
            <Span key={`error-${index}`}>
              {`${error.message} - ${error.debugMessage}`}
            </Span>
          ))}
        </Error>
      )}
    </div>
  );
}

function withRedux({ appElement, globalError }) {
  return { appElement, errors: globalError.errors };
}

export default connect(withRedux, { clearGlobalErrors, signOut })(
  withRouter(Page),
);
