import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Dashboard } from '../containers';
import Login from '../containers/authentication/login';
import Register from '../containers/authentication/register';
import SetEmailForPasswordReset from '../containers/authentication/setEmailForPasswordReset';
import PasswordReset from '../containers/authentication/resetPassword';
import { useHistory, useLocation, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setLastLocation } from '../redux/actions/lastLocation';
import Page from '../pages';

export function Routes({ user, lastLocation, setLastLocation }) {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname !== '/login' &&
      location.pathname !== '/set-email-for-password-reset'
    ) {
      setLastLocation(location);
    }
  }, [location]);

  useEffect(() => {
    if (user.authenticationToken) {
      history.push(lastLocation.pathname);
    } else {
      // @TODO: for some strange reason this gets called on hard page reload, too
      history.push('/login');
    }
  }, [user]);

  return (
    <Page>
      <Switch>
        <Route
          exact
          path="/set-email-for-password-reset"
          component={SetEmailForPasswordReset}
        />
        <Route exact path="/reset-password" component={PasswordReset} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact component={Dashboard} />
        <Route exact component={Dashboard} />
      </Switch>
    </Page>
  );
}

export default connect(({ user, lastLocation }) => ({ user, lastLocation }), {
  setLastLocation,
})(withRouter(Routes));
